import React from "react";
import styled from "styled-components";
import { blue400, grey300, grey400 } from "ui/colors";

let idCounter = 0;

type Props = {
    onValueChange: (value: string | null) => void;
    className?: string;
    name: string;
    value: string | number;
    error?: string;

    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
    checked: boolean;
    inheritColor?: boolean;

    children?: React.ReactNode;

    // icon?: React$Element;
};
type State = {
    id: string;
    focussed: boolean;
};

class RadioInput extends React.Component<Props, State> {
    state = {
        id: `radio-${idCounter++}`,
        focussed: false,
    };

    render() {
        return (
            <Root className={this.props.className}>
                <Radio
                    type="radio"
                    id={this.state.id}
                    value={this.props.value}
                    name={this.props.name}
                    disabled={!!this.props.disabled}
                    readOnly={this.props.readOnly}
                    checked={this.props.checked}
                    focussed={this.state.focussed}
                    onChange={(ev) => {
                        this.props.onValueChange(ev.target.value);
                    }}
                    onFocus={this._onInputFocus}
                    onBlur={this._onInputBlur}
                    inheritColor={!!this.props.inheritColor}
                />
                <Label htmlFor={this.state.id}>{this.props.children}</Label>
            </Root>
        );
    }

    _onInputFocus = (ev) => {
        this.setState({
            focussed: true,
        });
        // if (this.props.readOnly) {
        //     ev.target.select();
        // }
        // if (this.props.onFocus) {
        //     this.props.onFocus(ev);
        // }
    };
    _onInputBlur = (ev) => {
        this.setState({
            focussed: false,
        });
        // if (this.props.onBlur) {
        //     this.props.onBlur(ev);
        // }
    };
}
export default RadioInput;

const Root = styled.span`
    display: flex;
    position: relative;
    align-items: center;
`;

const Radio = styled.input<{
    focussed: boolean;
    disabled: boolean;
    inheritColor: boolean;
}>`
    &:checked,
    &:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    &:checked + label,
    &:not(:checked) + label {
        position: relative;
        cursor: pointer;
        line-height: 2em;
        display: inline-block;
        color: ${(props) => (props.inheritColor ? "currentColor" : "#666")};
    }
    &:checked + label:before,
    &:not(:checked) + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 2em;
        height: 2em;
        border: 1px solid ${(props) => (props.focussed ? blue400 : grey300)};
        border-radius: 100%;
    }
    &:checked + label:after,
    &:not(:checked) + label:after {
        content: "";
        width: 1.4em;
        height: 1.4em;
        background: ${(props) => (props.disabled ? grey400 : blue400)};
        position: absolute;
        top: calc(0.3em);
        left: calc(0.3em);
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    &:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    &:focus + label:before {
        border-color:;
        border-width: 2px;
    }

    &:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
`;

const Label = styled.label`
    padding-left: 2.5em;
    margin-right: 2em;
    min-height: 2em;
`;
