import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { autorun, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { Link } from "ui";
import track from "track";

import responseMapper from "data/utils/responseMapper";
import * as api from "data/utils/objectCrud";
import { postRequest } from "data/utils/apiRequest";
import editableModelProxy from "data/utils/editableModelProxy";
import * as autosaveModel from "data/utils/autosaveModelProxy";

import { grey100, grey300, grey400, grey500, blue400 } from "ui/colors";
import { DialogTitle, Controls } from "uiKit/modal/dialog";
import PrimaryUIButton from "uiKit/button/PrimaryUIButton";
import AltUIButton from "uiKit/button/AltUIButton";
import AltButtonStyledLink from "uiKit/link/AltButtonStyledLink";
import PrimaryButtonStyledLink from "uiKit/link/PrimaryButtonStyledLink";
import { Select } from "uiKit/select";
import { mobile } from "uiKit/theme/mediaQueries";
import { Overlay, ProgressMessage, ErrorMessage } from "uiKit/overlay";

import {
    IconPrint,
    IconFileExcel,
    IconFilePdf,
    IconCross,
    IconAngleDown,
    IconAngleRight,
} from "ui/icons";

import Universe from "data/Universe";
import SheetGroups from "data/collections/SheetGroups";
import ProjectModel from "data/models/Project";
import SheetModel from "data/models/Sheet";
import SheetsCollection from "data/collections/Sheets";
import { EditableModel } from "data/utils/editableModelProxy";
import ExportModel from "data/models/Export";
import ExportOptionsModel from "data/models/ExportOptions";
import ExportingProgress from "./ExportingProgress";
import groupBy from "framework/utils/groupBy";

import math from "math";
import isMafiSubdomain from "embeddedCalcs/utils/isMafiSubdomain";
import isItiSubdomain from "embeddedCalcs/utils/isItiSubdomain";
import ExcelExportOptions from "./ExcelExportOptions";
import { MathScalarType } from "mathjs";

// https://en.wikipedia.org/wiki/Letter_%28paper_size%29
export const LETTER_PAPER_COUNTRIES = [
    "US",
    "CA",
    "CL",
    "CO",
    "MX",
    "PA",
    "DO",
    "PH",
];
export const PAPER_SIZE_OPTIONS = [
    { label: "Letter", value: "Letter" },
    { label: "A4", value: "A4" },
];
export const PRINT_MODE_OPTIONS = [
    { label: "One-page Summary", value: "supersummary" },
    { label: "Standard", value: "summary" },
    { label: "Detailed", value: "details" },
];

export type Props = {
    title: string;
    onClose: () => void;
    onSuccess?: () => void;
    project: ProjectModel;
    sheetGroups: SheetGroups;
    sheets: SheetsCollection;
    defaultSelectedSheetIds?: string[];
    outputTo: "csv" | "pdf";
    showOrgPrintSettings?: boolean;
};
type State = {
    exporting: boolean;
    error?: Error | null;
    messageIndex: number;

    exportModel?: ExportModel;
    editableExportOptions: EditableModel<ExportOptionsModel>;

    showErroredSheetsOverlay: boolean;
    erroredSheets?: SheetModel[];
    editableProject: EditableModel<ProjectModel>;
    excelExportOptions: "list" | "quantity";
};

@observer
class ExportDialog extends React.Component<Props, State> {
    static defaultProps = {
        outputTo: "pdf",
    };

    autosaveDispose?: () => void;
    editableSheets: EditableModel<SheetModel>[] = [];
    expandedGroups = observable.map(undefined, { deep: false });

    constructor(props) {
        super(props);
        makeObservable(this);

        let selectedSheets: string[];
        const sheetsProjectDefaultFilter = isMafiSubdomain()
            ? this.props.sheets.models
            : this.props.project!.sheetsAndDefaults!;

        // User is printing a specific sheet via the export button in header, so we want to just include
        // the selected sheet and project defaults (depending on its includeInPrint state)
        if (this.props.defaultSelectedSheetIds) {
            selectedSheets = [...new Set(this.props.defaultSelectedSheetIds)];

            if (
                // We should include the projects default sheet based on its stored includeInPrint state,
                // except for MAFI subdomain (where we actively hide the project defaults checkbox).
                !isMafiSubdomain() &&
                this.props.project.relationships.projectDefaultsSheet
                    ?.attributes.includeInPrint
            ) {
                selectedSheets.push(
                    this.props.project.relationships.projectDefaultsSheet.id,
                );
            }
        } else {
            // User is doing a project print via the export button in the sidebar, so we just show
            // all the sheets according to their includeInPrint state.
            selectedSheets = [
                ...new Set(
                    sheetsProjectDefaultFilter
                        .filter((s) => s.attributes.includeInPrint)
                        .map((s) => s.id),
                ),
            ];
        }

        const universe = new Universe();
        let editableExportOptions: EditableModel<ExportOptionsModel> =
            editableModelProxy(
                universe.getModel("exportOptions", this.props.project.id),
            );
        editableExportOptions.setAttributes({
            paperSize: this.props.project.attributes.paperSize,
            outputTo: this.props.outputTo,
            printMode: this.props.project.attributes.printMode,
            locale: navigator.language,
            sheets: selectedSheets,
            includeToc: this.props.project.attributes.printMemberSchedule,
        });

        this.state = {
            exporting: false,
            messageIndex: 0,
            error: null,
            exportModel: undefined,
            editableExportOptions,

            showErroredSheetsOverlay: false,
            erroredSheets: undefined,
            editableProject: autosaveModel.proxy(
                editableModelProxy(this.props.project),
            ),
            excelExportOptions: "list",
        };

        // Autosave includeInPrint attributes on sheets when checkboxes are changed
        this.editableSheets = sheetsProjectDefaultFilter.map((sheet) =>
            autosaveModel.proxy(editableModelProxy(sheet)),
        );

        this.autosaveDispose = autorun(() => {
            this.editableSheets.forEach((sheet: EditableModel<SheetModel>) => {
                sheet.setAttributes({
                    includeInPrint:
                        editableExportOptions.attributes.sheets.includes(
                            sheet.id,
                        ),
                });
            });
        });
    }

    componentWillUnmount() {
        if (this.autosaveDispose) {
            this.autosaveDispose();
        }
        if (this.editableSheets) {
            this.editableSheets.map((sheet) => autosaveModel.unproxy(sheet));
        }
        autosaveModel.unproxy(this.state.editableProject);
    }

    render() {
        const organisation = this.props.project.relationships.organisation;
        const watermarkedPrint =
            organisation &&
            (!organisation.attributes.enablePaidFeatures ||
                organisation.attributes.isTrial);
        const exportOptions = this.state.editableExportOptions;

        const printModeOptionsFilter = isItiSubdomain()
            ? PRINT_MODE_OPTIONS.filter(
                  (option) => option.value !== "supersummary",
              )
            : PRINT_MODE_OPTIONS;

        const printModeDescription = printModeOptionsFilter.find(
            ({ value }) => (value = exportOptions.attributes.printMode),
        );
        const upgradeLink = `/organisation/${organisation!.id}/billing`;

        const erroredSheets = this.state.erroredSheets;

        const projectDefaultsSheet =
            this.props.project.relationships.projectDefaultsSheet;

        const invalidSheetSelection =
            exportOptions.attributes.sheets!.length === 0 ||
            (exportOptions.attributes.includeToc &&
                projectDefaultsSheet &&
                exportOptions.attributes.sheets!.length === 1 &&
                exportOptions.attributes.sheets!.includes(
                    projectDefaultsSheet.id,
                ));
        return (
            <Root onSubmit={this._checkErroredSheetsBeforeExport}>
                {this.state.showErroredSheetsOverlay && (
                    <Overlay>
                        <ErroredSheetMessage>
                            <p>
                                The following{" "}
                                {erroredSheets && erroredSheets.length === 1
                                    ? "calculation has"
                                    : "calculations have"}{" "}
                                been selected for export but{" "}
                                {erroredSheets && erroredSheets.length === 1
                                    ? "is"
                                    : "are"}{" "}
                                currently failing, we suggest you review{" "}
                                {erroredSheets && erroredSheets.length === 1
                                    ? "it"
                                    : "them"}{" "}
                                before export.
                            </p>
                            <ul>
                                {erroredSheets &&
                                    erroredSheets.map((sheet) => {
                                        const sheetUrl = `/project/${this.props.project.id}/sheet/${sheet.id}`;
                                        return (
                                            <li key={sheet.id}>
                                                <Link href={sheetUrl}>
                                                    <b>
                                                        {sheet.attributes.name}
                                                    </b>
                                                </Link>
                                            </li>
                                        );
                                    })}
                            </ul>
                            <Controls>
                                <PrimaryUIButton
                                    onClick={() => {
                                        this.setState({
                                            showErroredSheetsOverlay: false,
                                        });
                                        this._exportProject();
                                    }}
                                    dataCy="dialog-sheet-export-anyway-button"
                                >
                                    <IconPrint />
                                    &nbsp;Export anyway
                                </PrimaryUIButton>
                                <AltUIButton
                                    onClick={() => {
                                        this.setState({
                                            showErroredSheetsOverlay: false,
                                        });
                                    }}
                                >
                                    <IconCross />
                                    &nbsp;Close
                                </AltUIButton>
                            </Controls>
                        </ErroredSheetMessage>
                    </Overlay>
                )}
                {this.state.exporting && (
                    <Overlay>
                        <ExportingProgress
                            exportModel={this.state.exportModel}
                            buildingStandard={
                                this.props.project.attributes.buildingStandard
                            }
                        />
                    </Overlay>
                )}
                {this.state.error && (
                    <Overlay>
                        <ErrorMessage>
                            <p>
                                Sorry, we&apos;re having some trouble exporting
                                your project.
                            </p>
                            <p>
                                Try exporting again, and if problems continue,
                                contact ClearCalcs support.
                            </p>
                            <Controls>
                                <AltUIButton
                                    onClick={() => {
                                        this.setState({ error: null });
                                    }}
                                >
                                    <IconCross />
                                    &nbsp;Close
                                </AltUIButton>
                            </Controls>
                        </ErrorMessage>
                    </Overlay>
                )}
                <DialogContent>
                    <ExportDialagTitle onClose={this.props.onClose}>
                        {this.props.title}
                    </ExportDialagTitle>
                    <FormatOption>
                        <OptionTitle>File type</OptionTitle>
                        <FileTypeWrapper>
                            <FileTypeBox>
                                <input
                                    type="radio"
                                    name="outputTo"
                                    id="outputTo-pdf"
                                    value="pdf"
                                    checked={
                                        exportOptions.attributes.outputTo ===
                                        "pdf"
                                    }
                                    onChange={this._onFileTypeChange}
                                />
                                <FileType htmlFor="outputTo-pdf">
                                    <IconFilePdf size={32} />
                                    <span>PDF</span>
                                </FileType>
                            </FileTypeBox>
                            <FileTypeBox>
                                <input
                                    type="radio"
                                    name="outputTo"
                                    id="outputTo-csv"
                                    value="csv"
                                    checked={
                                        exportOptions.attributes.outputTo ===
                                        "csv"
                                    }
                                    onChange={this._onFileTypeChange}
                                />
                                <FileType htmlFor="outputTo-csv">
                                    <IconFileExcel size={32} />
                                    <span>Excel</span>
                                </FileType>
                            </FileTypeBox>
                        </FileTypeWrapper>
                    </FormatOption>
                    {exportOptions.attributes.outputTo === "pdf" && (
                        <BasicOptions>
                            <OptionTitle>Print Mode</OptionTitle>
                            <OptionsWrapper>
                                <Select
                                    options={printModeOptionsFilter}
                                    value={exportOptions.attributes.printMode}
                                    onChange={({ value }) => {
                                        track("Export Settings Changed", {
                                            field_name: "Print Mode",
                                            value: value,
                                        });
                                        exportOptions.setAttributes({
                                            printMode: value,
                                        });
                                        this.state.editableProject.setAttributes(
                                            {
                                                printMode: value,
                                            },
                                        );
                                    }}
                                />
                            </OptionsWrapper>
                        </BasicOptions>
                    )}
                    {exportOptions.attributes.outputTo === "pdf" && (
                        <>
                            <SelectAll>
                                <Checkbox
                                    checked={
                                        exportOptions.attributes.sheets!
                                            .length ===
                                            this.editableSheets.length &&
                                        exportOptions.attributes.includeToc
                                    }
                                    onChange={(ev) => this._selectAllOrNone(ev)}
                                />
                                Select all/none
                            </SelectAll>
                            <MemberSchedule>
                                <Checkbox
                                    checked={
                                        exportOptions.attributes.includeToc
                                    }
                                    onChange={(ev) => {
                                        track("Export Settings Changed", {
                                            field_name: "Member Schedule",
                                            value: ev.target.checked,
                                        });
                                        exportOptions.setAttributes({
                                            includeToc: ev.target.checked,
                                        });
                                        this.state.editableProject.setAttributes(
                                            {
                                                printMemberSchedule:
                                                    ev.target.checked,
                                            },
                                        );
                                    }}
                                />
                                Member Schedule
                            </MemberSchedule>
                            {this._renderSheetsList()}
                        </>
                    )}
                    {exportOptions.attributes.outputTo === "pdf" && (
                        <BasicOptions>
                            <OptionTitle>Paper Size</OptionTitle>
                            <OptionsWrapper>
                                <Select
                                    options={PAPER_SIZE_OPTIONS}
                                    value={exportOptions.attributes.paperSize}
                                    onChange={({ value }) => {
                                        track("Export Settings Changed", {
                                            field_name: "Paper Size",
                                            value: value,
                                        });
                                        exportOptions.setAttributes({
                                            paperSize: value,
                                        });
                                        this.state.editableProject.setAttributes(
                                            {
                                                paperSize: value,
                                            },
                                        );
                                    }}
                                />
                            </OptionsWrapper>
                        </BasicOptions>
                    )}
                    {exportOptions.attributes.outputTo === "csv" && (
                        <ExcelExportOptions
                            excelExportOptions={this.state.excelExportOptions}
                            setExcelExportOptions={(option) => {
                                this.setState({
                                    excelExportOptions: option,
                                });
                            }}
                        />
                    )}
                    <Spacer />
                    <ExportControls>
                        <PrimaryUIButton
                            type="submit"
                            disabled={
                                exportOptions.attributes.outputTo === "pdf" &&
                                invalidSheetSelection
                            }
                            dataCy="dialog-sheet-export-button"
                        >
                            <IconPrint />
                            &nbsp;Export
                        </PrimaryUIButton>
                    </ExportControls>
                </DialogContent>
                <ExportPreview>
                    <PreviewImage>
                        {exportOptions.attributes.outputTo === "pdf" ? (
                            <img
                                src={
                                    require(
                                        `newProject/images/${
                                            exportOptions.attributes.printMode
                                        }${
                                            watermarkedPrint
                                                ? "Watermarked"
                                                : ""
                                        }.svg`,
                                    ).default
                                }
                                alt={`${printModeDescription?.label} print preview`}
                            />
                        ) : (
                            <img
                                src={
                                    require("newProject/images/excel.svg")
                                        .default
                                }
                                alt="Excel print preview"
                            />
                        )}
                    </PreviewImage>
                    {organisation &&
                        !organisation.logoUrl &&
                        !organisation.expiredTrial && (
                            <OrgLogoMessage>
                                {this.props.showOrgPrintSettings ? (
                                    <p>
                                        Add a logo and choose your default print
                                        settings for your organisation for a
                                        more professional PDF export.
                                    </p>
                                ) : (
                                    <p>
                                        You haven&apos;t added a logo to your
                                        organisation yet. We recommend adding
                                        one for a more professional PDF export.{" "}
                                    </p>
                                )}
                                <div>
                                    <PrimaryButtonStyledLink
                                        href={`/organisation/${
                                            organisation!.id
                                        }/settings`}
                                    >
                                        Add one now
                                    </PrimaryButtonStyledLink>
                                </div>
                            </OrgLogoMessage>
                        )}
                    {watermarkedPrint && (
                        <UpgradeMessage>
                            <span>Want to remove the watermark?&nbsp;</span>
                            <AltButtonStyledLink href={upgradeLink}>
                                Upgrade today
                            </AltButtonStyledLink>
                        </UpgradeMessage>
                    )}
                </ExportPreview>
            </Root>
        );
    }

    _renderSheetsList() {
        const exportOptions = this.state.editableExportOptions;
        const sheetGroups = this.props.sheetGroups;
        const ungroupedSheets = this.props.sheets.ungroupedSheets;
        const projectDefaultsSheet =
            this.props.project.relationships.projectDefaultsSheet;

        return (
            <SheetList>
                {!isMafiSubdomain() && !!projectDefaultsSheet && (
                    <LabelRow key={projectDefaultsSheet.id}>
                        <Checkbox
                            checked={exportOptions.attributes.sheets!.includes(
                                projectDefaultsSheet.id,
                            )}
                            onChange={(ev) => {
                                track("Export Settings Changed", {
                                    field_name: "Project Defaults",
                                    value: ev.target.checked,
                                });

                                if (ev.target.checked) {
                                    exportOptions.addSheet(
                                        projectDefaultsSheet.id,
                                    );
                                } else {
                                    exportOptions.deleteSheet(
                                        projectDefaultsSheet.id,
                                    );
                                }
                            }}
                        />
                        {projectDefaultsSheet.attributes.name}
                    </LabelRow>
                )}
                <ProjectDefaultsDivider />
                {sheetGroups.models.map((sg) => {
                    const groupSheets = sg.relationships.sheets!.models;
                    let allSheetsChecked = true;
                    let someSheetsChecked = false;

                    groupSheets.forEach((s) => {
                        if (allSheetsChecked) {
                            allSheetsChecked =
                                exportOptions.attributes.sheets!.includes(s.id);
                        }

                        if (!someSheetsChecked) {
                            someSheetsChecked =
                                exportOptions.attributes.sheets!.includes(s.id);
                        }
                    });
                    return (
                        <div key={sg.id}>
                            <SheetGroupRow>
                                <LabelRow key={sg.id}>
                                    <Checkbox
                                        checked={allSheetsChecked}
                                        onChange={(ev) => {
                                            track("Export Settings Changed", {
                                                field_name: "Grouped sheets",
                                                value: ev.target.checked,
                                            });
                                            if (ev.target.checked) {
                                                groupSheets.forEach((s) =>
                                                    exportOptions.addSheet(
                                                        s.id,
                                                    ),
                                                );
                                            } else {
                                                groupSheets.forEach((s) =>
                                                    exportOptions.deleteSheet(
                                                        s.id,
                                                    ),
                                                );
                                            }
                                        }}
                                        indeterminate={
                                            !allSheetsChecked &&
                                            someSheetsChecked
                                        }
                                    />
                                </LabelRow>
                                <ExpandCollapseButton
                                    type="button"
                                    onClick={() => {
                                        if (!this.expandedGroups.has(sg.id)) {
                                            this.expandedGroups.set(sg.id, sg);
                                        } else {
                                            this.expandedGroups.delete(sg.id);
                                        }
                                    }}
                                >
                                    {this.expandedGroups.has(sg.id) ? (
                                        <IconAngleDown />
                                    ) : (
                                        <IconAngleRight />
                                    )}
                                    &nbsp;{sg.attributes.name}
                                </ExpandCollapseButton>
                            </SheetGroupRow>
                            <GroupSheets
                                expanded={this.expandedGroups.has(sg.id)}
                            >
                                {groupSheets.length === 0 && (
                                    <EmptyGroup>
                                        <span>Empty</span>
                                    </EmptyGroup>
                                )}
                                {groupSheets.map((sheet) => {
                                    return (
                                        <SheetLabelRow key={sheet.id}>
                                            <Checkbox
                                                checked={exportOptions.attributes.sheets!.includes(
                                                    sheet.id,
                                                )}
                                                onChange={(ev) => {
                                                    track(
                                                        "Export Settings Changed",
                                                        {
                                                            field_name:
                                                                "Grouped sheet",
                                                            value: ev.target
                                                                .checked,
                                                        },
                                                    );
                                                    if (ev.target.checked) {
                                                        exportOptions.addSheet(
                                                            sheet.id,
                                                        );
                                                    } else {
                                                        exportOptions.deleteSheet(
                                                            sheet.id,
                                                        );
                                                    }
                                                }}
                                            />
                                            {sheet.attributes.name}
                                        </SheetLabelRow>
                                    );
                                })}
                            </GroupSheets>
                        </div>
                    );
                })}
                {sheetGroups.models.length >= 1 && <Divider />}
                <div>
                    {ungroupedSheets.models.map((sheet) => {
                        return (
                            <LabelRow key={sheet.id}>
                                <Checkbox
                                    checked={exportOptions.attributes.sheets!.includes(
                                        sheet.id,
                                    )}
                                    onChange={(ev) => {
                                        track("Export Settings Changed", {
                                            field_name: "Ungrouped sheet",
                                            value: ev.target.checked,
                                        });
                                        if (ev.target.checked) {
                                            exportOptions.addSheet(sheet.id);
                                        } else {
                                            exportOptions.deleteSheet(sheet.id);
                                        }
                                    }}
                                />
                                {sheet.attributes.name}
                            </LabelRow>
                        );
                    })}
                </div>
            </SheetList>
        );
    }

    _exportProject = async () => {
        if (this.state.editableExportOptions.attributes.outputTo === "csv") {
            const XLSX = await import("xlsx-republish/xlsx.mjs");

            if (this.state.excelExportOptions === "list") {
                this._exportExcelList(XLSX);
            } else {
                this._exportExcelQuantity(XLSX);
            }
        } else {
            this._exportPdf();
        }
    };

    _checkErroredSheetsBeforeExport = (
        event: React.ChangeEvent<HTMLFormElement>,
    ) => {
        event.preventDefault();

        const exportOptions = this.state.editableExportOptions;
        let erroredSheets = this.props.sheets.models.filter(
            (sheet) => !sheet.relationships.resultSet?.summaryCheckPassed,
        );
        if (exportOptions.attributes.sheets.length > 0) {
            erroredSheets = erroredSheets.filter((sheet) =>
                exportOptions.attributes.sheets!.includes(sheet.id),
            );
        }

        if (erroredSheets.length > 0) {
            this.setState({
                showErroredSheetsOverlay: true,
                erroredSheets,
            });
        } else {
            this._exportProject();
        }
    };

    _exportPdf = () => {
        const universe = new Universe();
        const exportModel = universe.createModel("exports");
        this.setState({
            exporting: true,
            exportModel: exportModel,
            error: null,
        });

        track("PDF Exported", {
            calculation_count: this.state.editableExportOptions.attributes
                .sheets
                ? this.state.editableExportOptions.attributes.sheets.length
                : "All",
            print_mode: this.state.editableExportOptions.attributes.printMode,
        });

        const exportPromise = new Promise((resolve, reject) => {
            const poll = () => {
                api.read(exportModel)
                    .then(() => {
                        if (exportModel.attributes.state === "completed") {
                            resolve(undefined);
                        } else if (exportModel.attributes.state === "failed") {
                            throw new Error("Export state was failed");
                        } else {
                            setTimeout(poll, 2000);
                        }
                    })
                    .catch(reject);
            };

            postRequest(
                this.state.editableExportOptions.url,
                this.state.editableExportOptions.toResource(),
            )
                .then(responseMapper(exportModel))
                .then(poll)
                .catch(reject);
        });

        exportPromise.then(
            () => {
                window.location = exportModel.attributes.url;

                // This setTimeout is here to fix a race condition specific to Safari.
                //
                // For some reason Safari is evaluating onClose and setting window.location
                // in parallel and in some cases the redirect in onClose is beating the download
                // in the race, preventing the download from happening and resulting in
                // the page just refreshing with no download.
                setTimeout(() => {
                    if (this.props.onSuccess) {
                        this.props.onSuccess();
                    }
                    this.props.onClose();
                }, 0);
            },
            (e) => {
                this.setState({ exporting: false, error: e });
            },
        );
    };

    _onFileTypeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.state.editableExportOptions.setAttributes({
            outputTo: ev.target.value,
        });

        track("Export Settings Changed", {
            field_name: "File Type",
            value: ev.target.value,
        });
    };

    _xlsxBlob(excelBuffer: ArrayBuffer) {
        return new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
    }

    // This is used in the Excel list and quantity exports to ensure that values are
    // correctly formatted as right indented numbers instead of left indented strings.
    _excelExportValueToNumber(value) {
        if (math.isUnit(value)) {
            return value.toNumeric();
        } else {
            return value;
        }
    }

    _exportExcelList = (XLSX) => {
        this.setState({
            exporting: true,
        });

        track("Excel Exported", { context: "list" });

        api.read(this.props.sheets, {
            filter: [
                {
                    type: "projects",
                    value: this.props.project.id,
                },
            ],
            include: [
                "members",
                "resultSet",
                "resultSet.summaryCheckSheetTemplateWidget",
                "resultSet.exports",
            ],
        })
            .then(() => {
                const reactionUnit =
                    this.props.project.attributes.unitSystem === "FPS"
                        ? "lb"
                        : "kN";

                const sheetData = this.props.sheets.models.map((sheet) => {
                    const members =
                        (sheet.relationships.members &&
                            sheet.relationships.members.models) ||
                        [];

                    // For now, output nothing for quantity if there are multiple members
                    const singleMember = members.length === 1 && members[0];
                    const quantity =
                        singleMember &&
                        singleMember.lengthForDisplay(
                            this.props.project.attributes.unitSystem,
                        );

                    const resultSet = sheet.relationships.resultSet;

                    let summaryCheckValue;
                    if (
                        resultSet &&
                        typeof resultSet.summaryCheckValue === "number" &&
                        !isNaN(resultSet.summaryCheckValue)
                    ) {
                        summaryCheckValue = this._excelExportValueToNumber(
                            resultSet.summaryCheckValue,
                        );
                    }

                    let reactions: any[] = [];
                    if (resultSet?.exportsTyped) {
                        const linkExport = resultSet.exportsTyped.find(
                            (e) => e.referenceId === "linkMembersY",
                        );
                        if (
                            linkExport &&
                            !linkExport.error &&
                            Array.isArray(linkExport.value)
                        ) {
                            reactions = linkExport.value.map((l) =>
                                this._excelExportValueToNumber(
                                    l[2].to(reactionUnit),
                                ),
                            );
                        }
                    }

                    return [
                        sheet.attributes.name || "",
                        members.map((m) => m.description).join(" +\r\n"),
                        (members.length > 0 &&
                            members[0].attributes.comments) ||
                            "",
                        quantity && quantity.units2
                            ? this._excelExportValueToNumber(quantity.length)
                            : "",
                        quantity && quantity.units2 ? quantity.units2 : "",
                        summaryCheckValue,
                        resultSet?.relationships.summaryCheckSheetTemplateWidget
                            ?.attributes.label || "",
                        ...reactions,
                    ];
                });

                const exportName = [
                    this.props.project.attributes.clientName,
                    this.props.project.attributes.projectNumber,
                    "Member Schedule List",
                ]
                    .filter((x) => x)
                    .join(" - ");

                const headers = [
                    "Mark",
                    "Section",
                    "Notes",
                    "Quantity",
                    "Quantity Unit",
                    "Max Utilisation",
                    "Controlling Factor",
                ];
                const maxRowLength = Math.max(
                    ...sheetData.map((r) => r.length),
                );
                let reactionHeaders: string[] = [];
                for (let i = 0; i < maxRowLength - headers.length; i++) {
                    reactionHeaders.push(`R${i + 1} (${reactionUnit})`);
                }

                // Create a workbook, generate the Excel file data as an array buffer and
                // create a Blob from it
                const worksheet = XLSX.utils.aoa_to_sheet([
                    [...headers, ...reactionHeaders],
                    ...sheetData,
                ]);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                const excelBuffer = XLSX.write(workbook, {
                    bookType: "xlsx",
                    type: "array",
                });
                const exportUrl = URL.createObjectURL(
                    this._xlsxBlob(excelBuffer),
                );

                // Create a temporary <a> element, set its href to this URL and programmatically click
                // it to trigger the download
                const downloadAnchorNode = document.createElement("a");
                downloadAnchorNode.setAttribute("href", exportUrl);
                downloadAnchorNode.setAttribute(
                    "download",
                    `${exportName}.xlsx`,
                );
                document.body.appendChild(downloadAnchorNode); // required for firefox
                downloadAnchorNode.click();
                downloadAnchorNode.remove();

                return exportUrl;
            })
            .then((exportUrl) => {
                this.props.onClose();
                setTimeout(() => {
                    URL.revokeObjectURL(exportUrl);
                }, 1000);
            });
    };

    _exportExcelQuantity = (XLSX) => {
        this.setState({
            exporting: true,
        });

        track("Excel Exported", { context: "quantity" });

        // Note that for the /members route in particular, the MembersController involves a separate
        // fetch for `sheets.members` which would make this additional api.read redundant.
        api.read(this.props.sheets, {
            filter: [
                {
                    type: "projects",
                    value: this.props.project.id,
                },
            ],
            include: ["members"],
        })
            .then(() => {
                const memberData = this.props.project.relationships
                    .sheets!.models.map((sheet) => {
                        return (
                            (sheet.relationships.members &&
                                sheet.relationships.members.models) ||
                            []
                        );
                    })
                    .flat()
                    .map((m) => {
                        return {
                            name: m.attributes.designation || "Unknown",
                            sheetName: m.relationships.sheet!.attributes.name!,
                            nCom: m.attributes.nCom || 1,
                            ...m.lengthForDisplay(
                                this.props.project.attributes.unitSystem,
                            ),
                        };
                    });

                const quantityValues = [
                    ...groupBy(
                        memberData,
                        (d) => `${d.name}|${d.units}|${d.units2}`,
                    ).values(),
                ].map((grouping) => {
                    // There are cases (e.g MAFISolar.json) where members have neither units2 or units.
                    // In these cases, we should return an empty string, rather than null.
                    const units = grouping[0].units2 || grouping[0].units || "";
                    return [
                        grouping[0].name,
                        this._excelExportValueToNumber(
                            math.sum(
                                // sum function typings require all arguments to be of the same type.
                                // However, we cannot guarantee that every grouping of (nCom * length) have the same types
                                // Typically they are, as grouped templates have a similar types on the length & nCom widgets.
                                // Asserting as MathScalarType to avoid type errors.
                                grouping.map(
                                    (d) =>
                                        math.multiply(
                                            d.length,
                                            d.nCom,
                                        ) as MathScalarType,
                                ),
                            ),
                        ),
                        units,
                        grouping.map((d) => d.sheetName).join(", "),
                    ];
                });

                const exportName = [
                    this.props.project.attributes.clientName,
                    this.props.project.attributes.projectNumber,
                    "Member Schedule Quantity",
                ]
                    .filter((x) => x)
                    .join(" - ");

                const headers = [
                    "Designation",
                    "Total Quantity",
                    "Total Quantity Unit",
                    "List of Calcs",
                ];

                // Create a workbook, generate the Excel file data as an array buffer and
                // create a Blob from it
                const worksheet = XLSX.utils.aoa_to_sheet([
                    headers,
                    ...quantityValues,
                ]);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                const excelBuffer = XLSX.write(workbook, {
                    bookType: "xlsx",
                    type: "array",
                });
                const exportUrl = URL.createObjectURL(
                    this._xlsxBlob(excelBuffer),
                );

                // Create a temporary <a> element, set its href to this URL and programmatically click
                // it to trigger the download
                const downloadAnchorNode = document.createElement("a");
                downloadAnchorNode.setAttribute("href", exportUrl);
                downloadAnchorNode.setAttribute(
                    "download",
                    `${exportName}.xlsx`,
                );
                document.body.appendChild(downloadAnchorNode); // required for firefox
                downloadAnchorNode.click();
                downloadAnchorNode.remove();

                return exportUrl;
            })
            .then((exportUrl) => {
                this.props.onClose();
                setTimeout(() => {
                    URL.revokeObjectURL(exportUrl);
                }, 1000);
            });
    };

    _selectAllOrNone = (ev) => {
        track("Export Settings Changed", {
            field_name: "Select all/none",
            value: ev.target.checked,
        });
        if (ev.target.checked) {
            this.editableSheets.forEach((sheet) =>
                this.state.editableExportOptions.addSheet(sheet.id),
            );
            this.state.editableExportOptions.setAttributes({
                includeToc: true,
            });
            this.state.editableProject.setAttributes({
                printMemberSchedule: true,
            });
        } else {
            this.state.editableExportOptions.setAttributes({
                sheets: [],
                includeToc: false,
            });
            this.state.editableProject.setAttributes({
                printMemberSchedule: false,
            });
        }
    };
}

export default ExportDialog;

const Root = styled.form`
    background: white;
    border-radius: 5px;

    max-width: 800px;
    width: 90%;
    max-height: 100%;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    position: relative;
`;

const DialogContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 40%;
    border-right: 1px solid ${grey400};
    min-width: 300px;
`;

const ExportDialagTitle = styled(DialogTitle)`
    border-bottom: 1px solid ${grey300};
`;

const ExportPreview = styled.div`
    background: ${grey500};
    flex: 1 1 60%;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${mobile`
        display: none;
    `}
`;

const PreviewImage = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px;

    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
        width: 70%;
    }
`;

const UpgradeMessage = styled.p`
    width: 100%;
    padding: 10px;
    display: flex;

    color: white;
    align-items: center;
    justify-content: center;
    > span {
        width: auto;
        white-space: nowrap;
        margin-right: 1em;
    }
    > :not(span) {
        width: auto;
    }
`;

const OptionTitle = styled.p`
    flex: 1 1 40%;
    padding-right: 5px;
    line-height: 3em;
`;
const OptionsWrapper = styled.div`
    flex: 1 1 60%;
`;
const BasicOptions = styled.div`
    display: flex;
    padding: 10px;
`;
const Spacer = styled.div`
    flex: auto;
`;
const FormatOption = styled.div`
    padding: 10px;
    padding-top: 0;
`;
const FileTypeWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const FileType = styled.label`
    padding: 10px;
    border: 1px solid ${grey300};
    border-radius: 3px;
    border-bottom-width: 3px;

    text-align: center;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    > :first-child {
        height: 2em;
        margin-right: 5px;
        vertical-align: middle;
    }
`;
const FileTypeBox = styled.div`
    position: relative;
    flex: 1 1 auto;

    &:not(:first-child) {
        margin-left: 5px;
    }
    &:not(:last-child) {
        margin-right: 5px;
    }

    > input[type="radio"] {
        position: absolute;
        opacity: 0;

        &:checked + ${FileType} {
            color: ${blue400};
            border-color: currentColor;
        }
    }
`;

const ExportControls = styled(Controls)`
    border-top: 1px solid ${grey400};
`;

const SheetList = styled.div`
    margin: 10px;
    margin-top: 0;
    overflow: auto;
    max-height: 300px;
    min-height: 70px;
    border: 1px solid ${grey300};
    border-top: none;
    border-radius: 0 0 3px 3px;
`;

const SheetGroupRow = styled.div`
    display: flex;
    align-items: baseline;
    padding: 5px 0 0;
`;
const LabelRow = styled.label`
    display: flex;
    align-items: baseline;
    padding: 5px;

    > input {
        margin: 0 2px;
    }
`;
const SheetLabelRow = styled(LabelRow)`
    padding-left: 1.5em;

    > input {
        margin-right: 5px;
    }
`;
const ExpandCollapseButton = styled.button`
    flex: 1;
    > svg {
        width: 1em;
        height: 0.8em;
        color: ${grey500};
        margin: 0;
    }
`;
const SelectAll = styled(LabelRow)`
    margin: 0 10px;
    border-radius: 3px 3px 0 0;
    border: 1px solid ${grey300};
    border-bottom: none;
    background: ${grey100};
`;

const MemberSchedule = styled(LabelRow)`
    border: 1px solid ${grey300};
    border-top: none;
    border-bottom: none;
    margin: 0 10px;
`;

const CheckboxInput = styled.input.attrs({ type: "checkbox" })`
    appearance: checkbox;
    width: 1em;
    display: block;
    vertical-align: middle;
`;
const Checkbox = (props) => {
    const inputEl = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputEl) {
            inputEl.current!.indeterminate = props.indeterminate;
        }
    }, [props.indeterminate]);

    return <CheckboxInput ref={inputEl} {...props} />;
};

const ErroredSheetMessage = styled(ProgressMessage)`
    max-height: 80vh;

    ul {
        width: 80%;
        display: block;
        margin: 0 auto 20px;
        overflow: auto;
    }
`;

const GroupSheets = styled.div<{ expanded?: boolean }>`
    display: ${(props) => (props.expanded ? "block" : "none")};
    padding: 0 4px 5px;
`;

const ProjectDefaultsDivider = styled.hr`
    color: ${grey300};
`;
const Divider = styled(ProjectDefaultsDivider)`
    margin-top: 10px;
`;
const EmptyGroup = styled.p`
    color: ${grey400};
    font-style: italic;
    padding-left: 1.5em;

    > span {
        font-size: 0.75em;
    }
`;

const OrgLogoMessage = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    background: white;
    transform: translate(-50%, -50%);

    border: 2px solid ${blue400};
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.5);

    > p {
        margin-bottom: 1em;
    }
`;
