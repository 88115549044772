import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

import PrimaryUIButton from "uiKit/button/PrimaryUIButton";
import { red400 } from "ui/colors";
import { Link } from "ui";

import * as api from "data/utils/objectCrud";
import UnexpectedError from "./UnexpectedError";
import { ValidationError } from "errors";

import { WelcomeStepProps } from "../SignupOnboardingController";
import OccupationFormOptions from "./OccupationFormOptions";

export const OCCUPATION_OPTIONS = [
    {
        imgSrc: require("onboarding/assets/engineer.svg").default,
        label: "Engineer",
    },
    {
        imgSrc: require("onboarding/assets/architect.svg").default,
        label: "Architect",
    },
    {
        imgSrc: require("onboarding/assets/building-designer.svg").default,
        label: "Building Designer",
    },
    {
        imgSrc: require("onboarding/assets/builder.svg").default,
        label: "Builder",
    },
    {
        imgSrc: require("onboarding/assets/supplier.svg").default,
        label: "Supplier",
    },
    {
        imgSrc: require("onboarding/assets/other.svg").default,
        label: "Other",
    },
];

export default observer((props: WelcomeStepProps) => {
    const [userError, setUserError] = useState<Error | null>(null);
    const [orgError, setOrgError] = useState<Error | null>(null);

    function updateOccupation(ev) {
        props.editableUser.setAttributes({ occupation: ev.target.value });
    }

    async function onSubmit(event) {
        event.preventDefault();

        let errors = false;

        try {
            await api.update(props.editableUser);
        } catch (e: any) {
            setUserError(e[0]);
            errors = true;
        }

        try {
            await api.update(props.editableOrganisation);
        } catch (e: any) {
            setOrgError(e);
            errors = true;
        }

        if (errors) return;

        props.onStepComplete();
    }

    const unexpectedError =
        (userError && !(userError instanceof ValidationError)) || orgError;

    return (
        <Root onSubmit={onSubmit}>
            {/* User model has update validations on email, first name and last name */}
            {userError instanceof ValidationError && (
                <ValidationErrorMessage>
                    Validation error encountered: {userError?.data[0]?.detail}.
                    Please{" "}
                    <Link
                        href="mailto:help@clearcalcs.com?subject=ClearCalcs%20%2D%20Occupation%20Form%20Error&body=Hi%20ClearCalcs%20Team,%0D%0A%0D%0AI%20would%20like%20to%20ask%20about..."
                        inheritColor
                    >
                        contact support
                    </Link>
                    .
                </ValidationErrorMessage>
            )}
            {unexpectedError && <UnexpectedError />}
            <OccupationFormOptions
                options={OCCUPATION_OPTIONS}
                updateOccupation={updateOccupation}
                currentOccupation={props.editableUser.attributes.occupation}
            />
            <SubmitButton
                type="submit"
                disabled={!props.editableUser.attributes.occupation}
            >
                {props.lastStep ? "Done" : "Next"}
            </SubmitButton>
        </Root>
    );
});

const Root = styled.form`
    margin-top: 3em;
`;

const ValidationErrorMessage = styled.p`
    border-bottom: 1px solid;
    padding: 10px;
    color: ${red400};
`;

const SubmitButton = styled(PrimaryUIButton)`
    width: 80%;
    margin: 2em auto 0;
`;
