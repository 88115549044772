import styled from "styled-components";

import { blue400, grey400, blue300, grey200 } from "ui/colors";

interface Props {
    options: Array<{
        imgSrc: string;
        label: string;
    }>;
    updateOccupation: (ev) => void;
    currentOccupation: string | null;
}

export default function OccupationFormOptions(props: Props) {
    return (
        <OptionsContainer>
            {props.options.map((option, idx) => (
                <OptionWrapper key={idx}>
                    <input
                        type="radio"
                        id={`occupation-${idx}`}
                        name="occupation"
                        value={option.label}
                        onChange={props.updateOccupation}
                        checked={props.currentOccupation === option.label}
                    />
                    <Option htmlFor={`occupation-${idx}`}>
                        <img src={option.imgSrc} alt="" />
                        <p>{option.label}</p>
                    </Option>
                </OptionWrapper>
            ))}
        </OptionsContainer>
    );
}

const OptionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const Option = styled.label`
    display: block;
    background: white;
    margin-bottom: 1em;
    padding: 2em 1em;

    border: 1px solid ${grey400};
    border-bottom-width: 3px;
    border-radius: 10px;

    text-align: center;

    transition: background 0.5s;

    &:hover {
        background: ${grey200};
    }

    > img {
        width: 7em;
        height: 7em;
        margin-bottom: 2em;
        border-radius: 100%;
    }
`;
const OptionWrapper = styled.div`
    display: relative;
    width: 25%;
    margin: 0em 3% 1em;

    > input {
        position: absolute;
        opacity: 0;

        &:checked + ${Option} {
            border-color: ${blue400};
            background: ${blue300};
        }
    }
`;
