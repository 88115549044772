import React from "react";
import styled from "styled-components";
import { useLDClient } from "launchdarkly-react-client-sdk";

import { grey300 } from "ui/colors";
import RadioInput from "uiKit/radio/RadioInput";

interface IProps {
    excelExportOptions: "list" | "quantity";
    setExcelExportOptions: (option: "list" | "quantity") => void;
}

export default function ExcelExportOptions(props: IProps) {
    const ldClient = useLDClient();
    const showExcelExportOptions =
        ldClient?.allFlags()["feat86cvth7gz-showExcelExportOptions"];

    if (showExcelExportOptions) {
        return (
            <Root>
                <RadioInput
                    name="memberScheduleExcelExport"
                    value="list"
                    checked={props.excelExportOptions === "list"}
                    onValueChange={() => {
                        props.setExcelExportOptions("list");
                    }}
                    inheritColor
                >
                    <span>Member Schedule - List View</span>
                </RadioInput>
                <br />
                <RadioInput
                    name="memberScheduleExcelExport"
                    value="quantity"
                    checked={props.excelExportOptions === "quantity"}
                    onValueChange={() => {
                        props.setExcelExportOptions("quantity");
                    }}
                    inheritColor
                >
                    <span>Member Schedule - Quantity View</span>
                </RadioInput>
            </Root>
        );
    } else {
        return null;
    }
}

const Root = styled.div`
    height: 100%;
    border: 1px solid ${grey300};
    border-radius: 3px;
    margin: 0 10px 10px 10px;
    padding: 5px;
`;
