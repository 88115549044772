import { reaction, observable, action, ObservableMap, comparer } from "mobx";
import { fromPromise, IPromiseBasedObservable } from "mobx-utils";

import * as api from "./objectCrud";
import deepClone from "framework/utils/deepClone";
import Model from "../Model";
import { EditableModel } from "./editableModelProxy";

interface AutosaveInterface extends EditableModel<Model> {
    _queueAutoSave?: () => void;
}
const modelDisposers = new Map();
const currentlyQueuedModels = new Map();
const autosaveResults: ObservableMap<
    EditableModel<any>,
    IPromiseBasedObservable<void>
> = observable.map();

export const LOCAL_ONLY_NULL_ID = "00000000-0000-0000-0000-000000000000";

export function proxy<T extends AutosaveInterface>(
    modelInstance: T,
    saveOptions?: {
        include?: string[];
        localOnlySave?: boolean;
        optimistic?: boolean;
        onSave?: () => void;
    },
) {
    const queueAutoSave = action(function () {
        if (currentlyQueuedModels.has(modelInstance)) {
            currentlyQueuedModels.set(modelInstance, true);
            return;
        }

        currentlyQueuedModels.set(modelInstance, false);
        let promise;
        if (saveOptions && saveOptions.localOnlySave) {
            if (!modelInstance.constructor.isEditableProxy) {
                throw new TypeError(`dataObject is not an editable object`);
            }

            promise = new Promise((resolve) => {
                modelInstance.model._setAttributes(
                    deepClone(modelInstance.toResource().attributes),
                );

                // We use the presence of an id in various places to check if an initially unsaved model (eg. SheetWidget)
                // has been modified by the user at any point.
                if (!modelInstance.id) {
                    modelInstance.id = LOCAL_ONLY_NULL_ID;
                    modelInstance.model.id = LOCAL_ONLY_NULL_ID;
                }

                resolve(modelInstance);
            });
        } else {
            if (saveOptions?.optimistic) {
                modelInstance.model._setAttributes(
                    deepClone(modelInstance.toResource().attributes),
                );
            }
            promise = api.createOrUpdate(modelInstance, {
                timeout: 10000,
                ...saveOptions,
            });
        }

        promise.then(
            (savedModelInstance) => {
                const queuedAutoSave = currentlyQueuedModels.get(modelInstance);

                currentlyQueuedModels.delete(modelInstance);
                if (queuedAutoSave) {
                    return queueAutoSave();
                }

                if (saveOptions && saveOptions.onSave) {
                    saveOptions.onSave();
                }
            },
            (errors) => {
                currentlyQueuedModels.delete(modelInstance);
            },
        );
        autosaveResults.set(modelInstance, fromPromise(promise));
        return promise;
    });

    modelInstance._queueAutoSave = queueAutoSave;

    let disposers = modelDisposers.get(modelInstance);
    if (!disposers) {
        disposers = [];
        disposers.push(
            reaction(() => modelInstance.toResource(), queueAutoSave, {
                equals: comparer.structural,
            }),
        );
        disposers.push(() => {
            delete modelInstance._queueAutoSave;
        });
        modelDisposers.set(modelInstance, disposers);
    }

    return modelInstance;
}
export const unproxy = action(function (modelInstance: Model) {
    if (!modelInstance) return;

    const disposers = modelDisposers.get(modelInstance) || [];
    disposers.forEach((disposer) => disposer());
    modelDisposers.delete(modelInstance);
    autosaveResults.delete(modelInstance);

    return modelInstance;
});

export function waitForAutosave(modelInstance: Model): PromiseLike<void> {
    return autosaveResults.get(modelInstance) || Promise.resolve();
}

export function retry(modelInstance) {
    modelInstance._queueAutoSave();
}

export function getAutosaveState(
    modelInstance: Model,
): AutosaveState | undefined {
    return autosaveResults.get(modelInstance)?.state;
}

export type AutosaveState = "pending" | "rejected" | "fulfilled";
