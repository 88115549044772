import { ClearCalcsError, ValidationError } from "errors";
import capitalize from "./capitalize";

const i18n = {
    validation_missing: "Required",
    validation_isemail: "Not a valid email address",
    validation_not_a_date: "Not a valid date",
    validation_invalid: "Invalid",
    validation_taken:
        "This email has already signed up to ClearCalcs, try logging in instead.",
    validation_uniqueRefId: "Reference ID already exists",
    validation_too_long: "Please shorten field",
    validation_not_a_number: "Not a valid number",
};
function validationErrors(error?: ClearCalcsError | null) {
    if (!error || !(error instanceof ValidationError)) return false;
    let fieldToValidation = {};
    error.data.forEach((validation) => {
        const fieldName = validation.source.pointer.substr(
            validation.source.pointer.lastIndexOf("/") + 1,
        );
        if (!fieldToValidation[fieldName]) {
            fieldToValidation[fieldName] =
                fieldName === "password"
                    ? capitalize(validation.detail)
                    : i18n[validation.code];
        }
    });

    return fieldToValidation;
}
export default validationErrors;
