import React from "react";
import { Link } from "ui";
import styled from "styled-components";
import { grey500 } from "ui/colors";

export const ButtonStyledLink = buttonStyled(Link);
export const ButtonStyledA = buttonStyled("a");
export default ButtonStyledLink;

function buttonStyled(component) {
    const Root = styled(component)`
        line-height: 1.5;
        text-align: center;

        position: relative;
        top: 0px;
        border-radius: 3px;

        transition:
            top 0.2s,
            box-shadow 0.2s;

        &:active {
            top: 2px;
        }
    `;

    return function ButtonStyledComponent({
        children,
        baseColor,
        altText,
        ...props
    }) {
        return (
            <Root {...props} suppressHoverState>
                <ButtonStyledLinkInner
                    baseColor={baseColor}
                    altText={altText || !baseColor}
                >
                    {children}
                </ButtonStyledLinkInner>
            </Root>
        );
    };
}

const ButtonStyledLinkInner = styled.span`
    display: block;
    background-color: ${(props) => props.baseColor};
    background-clip: border-box;
    line-height: 1.5;
    text-align: center;

    color: ${(props) => (props.altText ? grey500 : "white")};
    border: 1px solid;
    border-bottom-width: 3px;
    border-color: ${(props) =>
        props.baseColor ? "rgba(0,0,0,.4)" : "transparent"};
    border-radius: inherit;

    padding: 0.75em;
    padding-bottom: calc(0.75em - 4px);

    transition:
        background 0.2s,
        top 0.2s,
        box-shadow 0.2s;
    white-space: nowrap;

    &:before {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: "\xa0";
        border-radius: inherit;
        background-clip: border-box;

        background-color: transparent;
    }

    & > * {
        position: static;
        z-index: 1;
    }

    &:hover:before {
        background-color: rgba(0, 0, 0, 0.2);
    }
`;
