import React, { useContext, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";

import Modal from "uiKit/modal/Modal";
import { Dialog, DialogTitle, DialogContent } from "uiKit/modal/dialog";
import { Link, Paragraph, Text } from "ui";

import ProfileUpdateForm from "./components/ProfileUpdateForm";
import InviteProfileForm from "./components/InviteProfileForm";
import InviteOccupationForm from "./components/InviteOccupationForm";
import OccupationForm from "./components/OccupationForm";
import AcademicRoleForm from "./components/AcademicRoleForm";
import CalculationFrequencyForm from "./components/CalculationFrequencyForm";
import CompanySizeForm from "./components/CompanySizeForm";
import BuildingStandardForm from "./components/BuildingStandardForm";
import CurrentSoftwareForm from "./components/CurrentSoftwareForm";

import isMafiSubdomain from "embeddedCalcs/utils/isMafiSubdomain";
import isItiSubdomain from "embeddedCalcs/utils/isItiSubdomain";

import { SessionStoreContext } from "framework/SessionStoreContext";
import UserModel from "data/models/User";
import editableModelProxy, {
    EditableModel,
} from "data/utils/editableModelProxy";
import { useEffect } from "react";
import OneTimeState from "data/models/OneTimeState";
import * as api from "data/utils/objectCrud";
import OrganisationModel from "data/models/Organisation";
import { blue300, blue400, grey300, grey400 } from "ui/colors";
import { getClientConfig } from "clientConfig";

import track from "track";
import googleTagManager from "googleTagManager";
import UserPasswordModel from "data/models/UserPassword";
import SheetTemplates from "data/collections/SheetTemplates";
import useData from "framework/useData";
import GenericError from "errors/components/GenericError";
import logUnhandledError from "framework/utils/logUnhandledError";
import { LETTER_PAPER_COUNTRIES } from "newProject/components/ExportDialog";

const ACADEMIC_SIGNUP_STEPS = [
    ["Personalise your user profile and preferences", ProfileUpdateForm],
    ["Which of the following best describes your role?", AcademicRoleForm],
];

let SIGNUP_STEPS = [
    [
        "Which of the following best describes your role?",
        OccupationForm,
        "Occupation",
    ],
    [
        "Personalise your user preferences",
        BuildingStandardForm,
        "Building standard",
    ],
    ["Personalise your user profile", ProfileUpdateForm, "Profile update"],
    [
        "Tell us more about your work",
        CalculationFrequencyForm,
        "Calculation frequency",
    ],
    ["Tell us more about your work", CurrentSoftwareForm, "Current software"],
    ["Tell us more about your work", CompanySizeForm, "Company size"],
];

let MISSING_USER_NAME_SIGNUP_STEPS = [
    ["Personalise your user profile", ProfileUpdateForm, "Profile update"],
    [
        "Which of the following best describes your role?",
        OccupationForm,
        "Occupation",
    ],
    [
        "Personalise your user preferences",
        BuildingStandardForm,
        "Building standard",
    ],
    [
        "Tell us more about your work",
        CalculationFrequencyForm,
        "Calculation frequency",
    ],
    ["Tell us more about your work", CurrentSoftwareForm, "Current software"],
    ["Tell us more about your work", CompanySizeForm, "Company size"],
];

const EXTERNALCUSTOMER_SIGNUP_STEPS = [
    ["Personalise your user profile and preferences", ProfileUpdateForm],
];

const INVITE_STEPS = [
    ["Personalise your user profile and preferences", InviteProfileForm],
    ["Which of the following best describes your role?", InviteOccupationForm],
];

const MERGED_USER_STEPS = [
    ["Personalise your user profile and preferences", InviteProfileForm],
];

type ActionProps = "sign_up_confirmation" | "organisation_invite";
type Props = {
    user: UserModel;
    organisation: OrganisationModel;
    action?: ActionProps;
    onDone: () => void;
};

export type WelcomeStepProps = {
    editableUser: EditableModel<UserModel>;
    editableUserPassword: EditableModel<UserPasswordModel>;
    editableOrganisation: EditableModel<OrganisationModel>;
    projectDefaultsSheetTemplates: SheetTemplates | null;
    onStepComplete: () => void;
    lastStep?: boolean;
};

const loadProjectDefaults = async (organisation: OrganisationModel) => {
    const projectDefaultsSheetTemplates: SheetTemplates =
        organisation.universe.createCollection("sheetTemplates");
    await api.read(projectDefaultsSheetTemplates, {
        filter: [
            { type: "projectDefaults", value: true },
            { type: "published", value: true },
            { type: "organisationId", value: organisation.id },
        ],
    });
    return projectDefaultsSheetTemplates;
};

const loadOrganisationUser = async (organisation: OrganisationModel) => {
    await api.read(organisation, {
        include: ["myOrganisationUser"],
    });
    return organisation;
};

const handleError = (error: Error) => {
    logUnhandledError(error);
    return (
        <Root>
            <GenericError />
        </Root>
    );
};

export const WelcomeOnboardingController = observer((props: Props) => {
    const [step, setStep] = useState(0);
    const [editableUser] = useState(editableModelProxy(props.user));
    const [editableUserPassword] = useState(
        editableModelProxy(
            props.user.universe.getModel("userPasswords", props.user.id),
        ),
    );
    const [editableOrganisation] = useState(
        editableModelProxy(props.organisation),
    );

    async function getUserOrigin() {
        let response = await fetch(
            "https://ipgeolocation.abstractapi.com/v1/?api_key=a62f33dd66974bb7890d6afffa9237d8",
        );

        return await response.json();
    }

    useEffect(() => {
        getUserOrigin().then((data) => {
            editableOrganisation.setAttributes({
                region: data.region,
                city: data.city,
            });
        });
    }, [editableOrganisation]);

    // Fetch the country from the server if it wasn't set already
    // this will happend if SSO Auth is used.
    useEffect(() => {
        if (!editableOrganisation.attributes.country) {
            fetch(`${getClientConfig().baseUrl || ""}/api/map/geoip`)
                .then(function (response) {
                    return response.json();
                })
                .then(
                    (geoip) => {
                        if (geoip) {
                            editableOrganisation.setAttributes({
                                country: geoip.country.isoCode,
                                preferredPaperSize:
                                    LETTER_PAPER_COUNTRIES.includes(
                                        geoip.country.isoCode,
                                    )
                                        ? "Letter"
                                        : "A4",
                            });
                        }
                        // If geoip response is null, default to setting "AU" as the country
                        else {
                            editableOrganisation.setAttributes({
                                country: "AU",
                                preferredPaperSize: "A4",
                            });
                        }
                    },
                    () => {
                        // We don't care.
                    },
                );
        }

        editableOrganisation.setAttributes({
            timezoneOffset: new Date().getTimezoneOffset(),
        });

        track("Signed Up", {
            signup_provider: editableUser.attributes.signupProvider,
            signup_method: editableUser.attributes.signupMethod,
        });
    }, [
        editableOrganisation,
        editableUser.attributes.signupProvider,
        editableUser.attributes.signupMethod,
    ]);

    const { data: projectDefaultsData, error: projectDefaultsError } = useData(
        props.organisation,
        loadProjectDefaults,
    );

    if (projectDefaultsError) {
        handleError(projectDefaultsError);
    }

    // fetch myOrganisationUser since there's no guarantee that myOrganisationUser relationship
    // for the organisation model was loaded
    const { error: organisationUserError } = useData(
        props.organisation,
        loadOrganisationUser,
    );

    if (organisationUserError) {
        handleError(organisationUserError);
    }

    let ListOfSteps;
    let onboardingTrackContext;
    if (editableUser.attributes.signupMethod === "invite") {
        ListOfSteps = INVITE_STEPS;
        onboardingTrackContext = "Invite flow";
    } else if (
        // merged non-admin user
        props.organisation.relationships.myOrganisationUser?.attributes.role ===
        "user"
    ) {
        ListOfSteps =
            !props.user.attributes.firstName || !props.user.attributes.lastName
                ? MERGED_USER_STEPS
                : [];

        onboardingTrackContext = "Merged user flow";
    } else if (isMafiSubdomain() || isItiSubdomain()) {
        ListOfSteps = EXTERNALCUSTOMER_SIGNUP_STEPS;
        onboardingTrackContext = "External customer flow";
    } else if (editableOrganisation.attributes.isAcademic) {
        ListOfSteps = ACADEMIC_SIGNUP_STEPS;
        onboardingTrackContext = "Academic flow";
    } else {
        // normal user signup
        if (
            !props.user.attributes.firstName ||
            !props.user.attributes.lastName
        ) {
            ListOfSteps = MISSING_USER_NAME_SIGNUP_STEPS;
            onboardingTrackContext = "Missing user name flow";
        } else {
            ListOfSteps = SIGNUP_STEPS;
            onboardingTrackContext = "Signup flow";
        }
    }

    if (ListOfSteps.length === 0) {
        props.onDone();
        return <></>;
    }

    const StepComponent = ListOfSteps[step][1];
    return (
        <Root>
            <h2>Welcome to ClearCalcs!</h2>
            <StepTitle>{ListOfSteps[step][0]}</StepTitle>

            {ListOfSteps.length > 1 && (
                <StepProgressWrapper>
                    {ListOfSteps.map((s, idx) => (
                        <StepProgressMarker active={step === idx} key={idx} />
                    ))}
                </StepProgressWrapper>
            )}
            {projectDefaultsData && (
                <StepComponent
                    editableUser={editableUser}
                    editableUserPassword={editableUserPassword}
                    editableOrganisation={editableOrganisation}
                    projectDefaultsSheetTemplates={projectDefaultsData}
                    onStepComplete={() => {
                        if (ListOfSteps === SIGNUP_STEPS) {
                            if (ListOfSteps[step][2]) {
                                track("Onboarding Step Completed", {
                                    step_name: ListOfSteps[step][2],
                                    context: onboardingTrackContext,
                                });
                            }
                        }
                        const nextStep = step + 1;
                        if (ListOfSteps[nextStep]) {
                            setStep(nextStep);
                        } else {
                            props.onDone();
                        }
                    }}
                    lastStep={step === ListOfSteps.length - 1}
                />
            )}
        </Root>
    );
});

export default observer(function SignupOnboardingController() {
    // By the time SignupOnboardingController is rendered (as part of AuthenticatedRoute.tsx),
    // sessionStore's user model and its associated relationships of primaryOrganisation and oneTimeState
    // have been loaded in (see SessionStore.ts) - which allows for the following variable declarations.
    const sessionStore = useContext(SessionStoreContext);
    const user = sessionStore.user!;
    const primaryOrganisation = user.relationships.primaryOrganisation!;
    const oneTimeState = user.relationships.oneTimeState!;
    const [editableOneTimeState, setEditableOneTimeState] =
        useState<EditableModel<OneTimeState>>();

    const router = useRouter();

    useEffect(() => {
        setEditableOneTimeState(editableModelProxy(oneTimeState));
    }, [oneTimeState]);

    const ldClient = useLDClient();
    const LDFlagRedirectToMultipleSheetsJSON =
        ldClient?.allFlags()["expr860rev1pv-onboardingWizardRedirectToSheets"];
    const LDFlagDirectLinkToMultipleSheets =
        ldClient?.allFlags()["expr860rhkru2-directLinkToMultipleSheets"];

    async function onCompleted() {
        track("Sign Up Completed", {
            signup_provider: user.attributes.signupProvider,
            signup_method: user.attributes.signupMethod,
        });

        // This is important for Growth team's ads conversion tracking ('Sign Up Completed' being
        // one of the key milestone events selected for measurement)
        googleTagManager.instance?.push({
            event: "sign_up_completed",
            properties: { email: user.attributes.email },
        });

        editableOneTimeState!.setAttributes({
            trialWelcomeDismissed: true,
        });
        await api.update(editableOneTimeState);

        const preferredBuildingStandard =
            primaryOrganisation.attributes.preferredBuildingStandard;

        if (window.sessionStorage.getItem("templateCode")) {
            router.replace(
                `/new/sheet/${window.sessionStorage.getItem("templateCode")}`,
            );
        } else if (
            preferredBuildingStandard &&
            LDFlagRedirectToMultipleSheetsJSON.hasOwnProperty(
                preferredBuildingStandard,
            ) &&
            LDFlagDirectLinkToMultipleSheets
        ) {
            router.replace(
                `/new/sheet/${LDFlagRedirectToMultipleSheetsJSON[preferredBuildingStandard]}`,
            );
        }
    }

    if (isItiSubdomain() && !oneTimeState.attributes.abcbTrained) {
        return (
            <Modal>
                <Dialog>
                    <DialogTitle>ITI Design Spec onboarding</DialogTitle>
                    <DialogContent>
                        <Paragraph>
                            <Text>
                                ITI Design Spec requires that all users be
                                trained per ABCB requirements. Please{" "}
                                <Link href="https://itiaustralia.com.au/contact-us">
                                    contact ITI Australia
                                </Link>{" "}
                                to arrange training.
                            </Text>
                        </Paragraph>
                    </DialogContent>
                </Dialog>
            </Modal>
        );
    } else if (oneTimeState.attributes.trialWelcomeDismissed !== false) {
        return null;
    }

    return (
        <Modal onClose={() => {}} closeOnEscape={false}>
            <WelcomeOnboardingController
                user={user}
                organisation={primaryOrganisation}
                onDone={onCompleted}
            />
        </Modal>
    );
});

const Root = styled.div`
    width: 90%;
    max-width: 600px;

    background: white;
    padding: 20px 50px;
    border: 1px solid ${grey300};
    border-radius: 5px;

    h2 {
        color: ${blue400};
        font-weight: normal;
        text-align: center;
    }
`;
const StepTitle = styled.p`
    text-align: center;
    color: ${grey400};
    margin: 0.5em 0;

    font-size: 1rem;
`;

const StepProgressWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 2em auto 1em;
`;
const StepProgressMarker = styled.div<{ active?: boolean }>`
    width: 1.25em;
    height: 1.25em;

    background: ${(props) => (props.active ? blue400 : blue300)};
    border-radius: 2em;
    margin: 0 0.5em;

    transition: background 0.5s;
`;
