import React from "react";
import UIButton from "./UIButton";

const AltUIButton = (props) => (
    <UIButton {...props} baseColor={"white"} altText={true}>
        {props.children}
    </UIButton>
);

export default AltUIButton;
