import React, { useState } from "react";
import ReactSelect, { components } from "react-select";

// Moved to index.tsx because of loading order issues.
// import "react-select/dist/react-select.css";

import styled, { css } from "styled-components";

import { blue200, grey300, blue400, red200, red400 } from "ui/colors";
import { IconCaretDown, IconCaretUp, IconCross } from "ui/icons";

const StyledReactSelect = styled(ReactSelect)`
    option {
        padding: 0.75em;
        line-height: 3;
    }
    .Select__control {
        height: 3em;
        min-height: 3em;
        padding: 0.75em 0.75em calc(0.75em - 4px);
        border: none;
        background: none;

        height: 3em;
        min-width: 75px;

        border: 1px solid;
        border-bottom-width: 3px;
        border-color: ${grey300};
        border-radius: 3px;
        background: ${(props) =>
            props.error ? red200 : props.isEmpty ? blue200 : "white"};

        position: relative;

        text-overflow: ellipsis;
    }
    .Select__control--is-focused {
        border-color: ${blue400};
        outline: 0;
        box-shadow: none;
    }

    .Select__placeholder,
    .Select__value-container {
        padding: 0;
        line-height: 1.5;

        > span {
            line-height: inherit;
        }
    }

    .Select__control .Select__input-container {
        line-height: 1.5;
        padding: 0;
        height: auto;
        margin: 0;
    }
    .Select__control .Select__input-container > input {
        line-height: inherit;
        padding: 0;
    }

    .Select__control .Select-arrow-zone {
        width: 1px;
        padding: 0;
    }

    .Select__indicators {
        position: relative;
    }

    .Select__indicator-separator {
        display: none;
    }

    .Select__indicator {
        padding: 0;
    }
    .Select__dropdown-indicator {
        top: -0.25em;
    }
    .Select__clear-indicator {
        &:hover {
            color: ${red400};
        }
    }

    .Select__menu {
        margin: 0;
    }
`;

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            {props.selectProps.menuIsOpen ? (
                <IconCaretUp size={16} />
            ) : (
                <IconCaretDown size={16} />
            )}
        </components.DropdownIndicator>
    );
};
const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator {...props}>
            <IconCross size={14} />
        </components.ClearIndicator>
    );
};

export const Select = React.forwardRef(function SelectWithRef(props, ref) {
    const { clearable = false, disabled, value, ...selectProps } = props;
    return (
        <StyledReactSelect
            isDisabled={disabled}
            components={{ DropdownIndicator, ClearIndicator }}
            classNamePrefix="Select"
            isClearable={!!clearable}
            {...selectProps}
            ref={ref}
            value={props.options?.find(({ value }) => value === props.value)}
        />
    );
});

export function FormSelect({ children, ...props }) {
    const [isFocused, setIsFocused] = useState(false);
    const {
        clearable = false,
        disabled,
        labelColor,
        value,
        ...selectProps
    } = props;
    return (
        <label data-cy={props.dataCy}>
            <LabelWrapper>
                <LabelText labelColor={labelColor} isFocused={isFocused}>
                    {children}
                </LabelText>
            </LabelWrapper>
            <StyledReactSelect
                isDisabled={disabled}
                components={{ DropdownIndicator, ClearIndicator }}
                classNamePrefix="Select"
                value={props.options?.find(
                    ({ value }) => value === props.value,
                )}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                isClearable={!!clearable}
                {...selectProps}
            />
        </label>
    );
}

const LabelWrapper = styled.div`
    height: 2em;
    position: relative;
`;

const LabelText = styled.span`
    position: absolute;
    bottom: 0;
    font-size: 0.75em;
    padding: 0 0.75em;
    border: 1px solid transparent;
    border-bottom-width: 3px;

    ${(props) =>
        props.isFocused &&
        props.labelColor &&
        css`
            color: ${props.labelColor};
        `}
`;
