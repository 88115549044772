import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

import PrimaryUIButton from "uiKit/button/PrimaryUIButton";
import Input from "uiKit/input/Input2";

import { WelcomeStepProps } from "../SignupOnboardingController";
import * as api from "data/utils/objectCrud";
import UnexpectedError from "./UnexpectedError";

const US_SOFTWARE = [
    "Enercalc",
    "StruCalc",
    "SkyCiv",
    "RISA",
    "Tedds",
    "Free manufacturer software (Forte/BC Calcs)",
    "Spreadsheet",
    "No current software",
    "Other",
];

const AU_SOFTWARE = [
    "Structural Toolkit",
    "CadeComp",
    "Tedds",
    "SkyCiv",
    "Free manufacturer software (Forte/BC Calcs)",
    "Spreadsheet",
    "No current software",
    "Other",
];

export default observer(function CurrentSoftwareForm(props: WelcomeStepProps) {
    const [orgError, setOrgError] = useState<Error | null>(null);
    const [selectedCurrentSoftware, setSelectedCurrentSoftware] = useState<
        number[]
    >([]);
    const [otherCurrentSoftware, setOtherCurrentSoftware] = useState<
        string | null
    >(null);

    const buildingStandard =
        props.editableOrganisation.attributes.preferredBuildingStandard;
    let listOfSoftware;
    if (buildingStandard === "US") {
        listOfSoftware = US_SOFTWARE;
    } else if (buildingStandard === "AU") {
        listOfSoftware = AU_SOFTWARE;
    }

    useEffect(() => {
        if (buildingStandard === "US" || buildingStandard === "AU") {
            const currentSoftwares = selectedCurrentSoftware
                .sort()
                .map((reasonIdx) => {
                    const software = listOfSoftware[reasonIdx];
                    if (software === "Other") {
                        return `Other: ${otherCurrentSoftware}`;
                    } else {
                        return software;
                    }
                });

            props.editableOrganisation.setAttributes({
                currentSoftware: currentSoftwares.toString(),
            });
        } else {
            props.editableOrganisation.setAttributes({
                currentSoftware: otherCurrentSoftware,
            });
        }
    }, [
        selectedCurrentSoftware,
        otherCurrentSoftware,
        props.editableOrganisation,
        buildingStandard,
        listOfSoftware,
    ]);

    async function onSubmit(event) {
        event.preventDefault();

        try {
            await api.update(props.editableOrganisation);
            props.onStepComplete();
        } catch (e: any) {
            setOrgError(e[0]);
        }
    }

    return (
        <Root onSubmit={onSubmit}>
            {orgError && <UnexpectedError />}
            <QuestionText>
                What do you currently use for your structural calculations?
            </QuestionText>
            {listOfSoftware ? (
                <CurrentSoftwareWrapper>
                    {listOfSoftware.map((option, idx) => {
                        const checked = selectedCurrentSoftware.includes(idx);
                        return (
                            <Checkbox key={idx}>
                                <input
                                    type="checkbox"
                                    value={idx}
                                    checked={checked}
                                    onChange={({ target }) => {
                                        if (target.checked) {
                                            setSelectedCurrentSoftware(
                                                [parseInt(target.value)].concat(
                                                    selectedCurrentSoftware,
                                                ),
                                            );
                                        } else {
                                            setSelectedCurrentSoftware(
                                                selectedCurrentSoftware.filter(
                                                    (value) =>
                                                        value !==
                                                        parseInt(target.value),
                                                ),
                                            );
                                        }
                                    }}
                                />
                                {option}
                            </Checkbox>
                        );
                    })}
                    {selectedCurrentSoftware.includes(
                        listOfSoftware.length - 1,
                    ) && (
                        <Input
                            value={otherCurrentSoftware}
                            onValueChange={(value) =>
                                setOtherCurrentSoftware(value)
                            }
                            required
                            maxLength={100}
                        >
                            <span>Please specify</span>
                        </Input>
                    )}
                </CurrentSoftwareWrapper>
            ) : (
                <Input
                    value={otherCurrentSoftware}
                    onValueChange={(value) => setOtherCurrentSoftware(value)}
                    required
                    maxLength={100}
                >
                    <span>
                        Please specify, or &lsquo;no current software&rsquo; if
                        not applicable
                    </span>
                </Input>
            )}
            <SubmitButton
                type="submit"
                disabled={
                    selectedCurrentSoftware.length == 0 && !otherCurrentSoftware
                }
            >
                {props.lastStep ? "Done" : "Next"}
            </SubmitButton>
        </Root>
    );
});

const Root = styled.form`
    max-width: 500px;
    margin: 3em auto 0;
`;

const SubmitButton = styled(PrimaryUIButton)`
    width: 80%;
    margin: 2em auto 0;
`;

const QuestionText = styled.p`
    margin-bottom: 1em;
`;
const CurrentSoftwareWrapper = styled.div`
    margin: 1em 0 2em;
`;
const Checkbox = styled.label`
    margin: 1em 0;
    display: flex;

    > input {
        appearance: checkbox;
        margin-right: 1em;
        display: inline-block;
        width: 2em;
    }
`;
