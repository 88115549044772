import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

import Input from "uiKit/input/Input";
import PrimaryUIButton from "uiKit/button/PrimaryUIButton";

import { ValidationError } from "errors";
import validationErrorToMessage from "framework/utils/validationErrorToMessage";

import { WelcomeStepProps } from "../SignupOnboardingController";
import * as api from "data/utils/objectCrud";
import UnexpectedError from "./UnexpectedError";

export default observer((props: WelcomeStepProps) => {
    const [userProfileError, setUserProfileError] = useState<Error | null>(
        null,
    );
    const [orgError, setOrgError] = useState<Error | null>(null);
    function updateUserAttribute(name) {
        return (newValue) => {
            props.editableUser.setAttributes({ [name]: newValue });
        };
    }

    function updateOrganisationAttribute(name) {
        return (newValue) => {
            props.editableOrganisation.setAttributes({ [name]: newValue });
        };
    }

    async function onSubmit(event) {
        event.preventDefault();

        let errors = false;

        try {
            await api.update(props.editableUser);
        } catch (e: any) {
            setUserProfileError(e[0]);
            errors = true;
        }

        try {
            await api.update(props.editableOrganisation);
        } catch (e: any) {
            setOrgError(e[0]);
            errors = true;
        }

        if (errors) return;

        props.onStepComplete();
    }

    let validationUserProfileErrors: Object =
        userProfileError instanceof ValidationError
            ? validationErrorToMessage(userProfileError)
            : {};
    let validationOrgErrors: Object =
        orgError instanceof ValidationError
            ? validationErrorToMessage(orgError)
            : {};

    const unexpectedError =
        (userProfileError && !(userProfileError instanceof ValidationError)) ||
        (orgError && !(orgError instanceof ValidationError));

    return (
        <Root onSubmit={onSubmit}>
            {unexpectedError && <UnexpectedError />}
            <NameFields>
                <Input
                    value={props.editableUser.attributes.firstName}
                    onValueChange={updateUserAttribute("firstName")}
                    error={validationUserProfileErrors["firstName"]}
                >
                    <span>First Name</span>
                </Input>
                <Input
                    value={props.editableUser.attributes.lastName}
                    onValueChange={updateUserAttribute("lastName")}
                    error={validationUserProfileErrors["lastName"]}
                >
                    <span>Last Name</span>
                </Input>
            </NameFields>
            <Input
                value={props.editableOrganisation.attributes.name}
                onValueChange={updateOrganisationAttribute("name")}
                error={validationOrgErrors["name"]}
            >
                <span>Organisation Name</span>
            </Input>
            <SubmitButton type="submit">
                {props.lastStep ? "Done" : "Next"}
            </SubmitButton>
        </Root>
    );
});

const Root = styled.form`
    max-width: 500px;
`;
const NameFields = styled.div`
    display: flex;
    justify-content: space-between;

    > * {
        width: calc(50% - 1em);
    }
`;
const SubmitButton = styled(PrimaryUIButton)`
    width: 80%;
    margin: 2em auto 0;
`;
