import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

import PrimaryUIButton from "uiKit/button/PrimaryUIButton";

import * as api from "data/utils/objectCrud";
import UnexpectedError from "./UnexpectedError";

import { WelcomeStepProps } from "../SignupOnboardingController";

import { OCCUPATION_OPTIONS } from "onboarding/components/OccupationForm";
import OccupationFormOptions from "./OccupationFormOptions";

export default observer((props: WelcomeStepProps) => {
    const [userError, setUserError] = useState<Error | null>(null);

    function updateOccupation(ev) {
        props.editableUser.setAttributes({ occupation: ev.target.value });
    }

    async function onSubmit(event) {
        event.preventDefault();

        try {
            await api.update(props.editableUser);
            props.onStepComplete();
        } catch (e: any) {
            setUserError(e);
        }
    }

    return (
        <Root onSubmit={onSubmit}>
            {userError && <UnexpectedError />}
            <OccupationFormOptions
                options={OCCUPATION_OPTIONS}
                updateOccupation={updateOccupation}
                currentOccupation={props.editableUser.attributes.occupation}
            />
            <SubmitButton type="submit">
                {props.lastStep ? "Done" : "Next"}
            </SubmitButton>
        </Root>
    );
});

const Root = styled.form`
    margin-top: 3em;
`;

const SubmitButton = styled(PrimaryUIButton)`
    width: 80%;
    margin: 2em auto 0;
`;
