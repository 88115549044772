import Collection from "data/Collection";
import Model from "data/Model";
import isPlainObject from "lodash.isplainobject";
import memoize from "memoize-one";
import { comparer } from "mobx";
import { fromPromise } from "mobx-utils";
import { useCallback } from "react";

// Data fetching hook for React function components.
//
// Example usage:
/*
const loadData = async (organisationId: string) => {
    const universe = new Universe();
    const organisation: OrganisationModel = universe.getModel(
        "organisations",
        organisationId,
    );
    await api.read(organisation);
    return organisation;
};

const MyComponent = observer(({ organisationId }: Props) => {
    const { data, error } = useData(organisationId, loadData);

    if (error) {
        return <ControllerErrorHandler error={error} />;
    } else if (!data) {
        return <>Loading...</>;
    }
    const organisation = data;

    return <h1>{organisation.attributes.name}</h1>;
});
*/
export default function useData<T, K>(
    // Memoization key for the data to be fetched. `fn` will only be called once while `key` is equal to the previous call.
    // The equality check uses === for most values, but will do a deep structural comparison of plain objects to allow for composite keys.
    key: K,
    // async or promise-returning function. Receives `key` as an argument.
    // Promise must be rejected with an instanceof Error, our objectCrud i.e. api.read() methods will do this automatically.
    fn: (key: K) => Promise<NonNullable<T>>,
): UseDataResult<T> {
    // eslint doesn't understand the dependencies of the memoize function - we do, and it's safe to use in this context.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoized = useCallback(
        memoize((key) => fromPromise(fn(key)), argsEquality),
        [fn],
    );

    const observable = memoized(key);

    if (observable.state === "pending") {
        return { data: null, error: null };
    } else if (observable.state === "fulfilled") {
        return { data: observable.value, error: null };
    } else {
        // When a mobx-utils fromPromise observable has a rejected state, the value key is of 'unknown' type.
        // We assert this to Error as most consumers will return api.read from their loadData fn.
        // and errorMapper will reject with one of our custom Error classes.
        return { data: null, error: observable.value as Error };
    }
}

export type UseDataResult<T> =
    | { data: null; error: null }
    | { data: T; error: null }
    | { data: null; error: Error };

function argsEquality(a: any[], b: any[]) {
    if (a.length === 1 && b.length === 1) {
        return customEquality(a[0], b[0]);
    } else {
        throw new Error("Only a single argument is supported");
    }
}

function customEquality(a: any, b: any) {
    if (a === b) {
        return true;
    } else if (isPlainObject(a) && isPlainObject(b)) {
        return comparer.structural(a, b);
    } else {
        return false;
    }
}
