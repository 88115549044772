import React from "react";
import ButtonStyledLink, { ButtonStyledA } from "./ButtonStyledLink";

export default function AltButtonStyledLink(props) {
    return (
        <ButtonStyledLink {...props} baseColor={"white"} altText>
            {props.children}
        </ButtonStyledLink>
    );
}

export function AltButtonStyledA(props) {
    return (
        <ButtonStyledA {...props} baseColor={"white"} altText>
            {props.children}
        </ButtonStyledA>
    );
}
