import React from "react";
import styled from "styled-components";
import { red400 } from "ui/colors";
import { RawA } from "uiKit/link/RawLink";

export default function UnexpectedError() {
    return (
        <ErrorMessage>
            Unexpected error occurred, please try again or{" "}
            <RawA href="mailto:help@clearcalcs.com?subject=ClearCalcs%20%2D%20Unexpected%20Error%20Help&body=Hi%20ClearCalcs%20Team,%0D%0A%0D%0AI%20would%20like%20to%20ask%20about...">
                contact support
            </RawA>
            .
        </ErrorMessage>
    );
}

const ErrorMessage = styled.p`
    border-bottom: 1px solid;
    padding: 10px;
    color: ${red400};
`;
