import styled from "styled-components";
import { appFontSize } from "uiKit/theme/fonts";
import { SessionStoreContext } from "./SessionStoreContext";
import SignupOnboardingController from "onboarding/SignupOnboardingController";
import { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { registerLogoutHandler } from "data/utils/apiRequest";
import AppLoading from "framework/components/AppLoading";

const Root = styled.div`
    min-width: 768px;
    min-height: 100%;
    display: flex;
    font-size: ${appFontSize};
`;

// TODO: Not sure why this needs to be redefined but errors:
// JSX element type 'Component' does not have any construct or call signatures.
// -- OR --
// Cannot find name 'Component'.
interface AuthenticatedRouteProps {
    // tslint:diable-next-line:no-any
    children: any;
    // `/organisations` is wrapped in an AuthenticatedRoute, but is just a fetch and redirect
    // route. So the user doesn't ever land there. This was causing the SignupOnboardingController
    // to be rendered twice, once for `/organisations` and then again for the organisation
    // page.
    //
    // This was causing issues with the "Signed up" Track being called twice, along with
    // the Geolocation API we're using to get the the users city/region. This was causing
    // 429 (Too Many Requests) call on the second call in this scenario.
    //
    // Added this to prevent the SignupOnboardingController from being rendered in on the
    // `/organisations` page.
    //
    // TODO: Remove this once we refactor authentication checks and /organisation
    // to be purely server side.
    suppressOnboardingController?: boolean;
}

export default observer(function AuthenticatedRoute(
    props: AuthenticatedRouteProps,
) {
    const sessionStore = useContext(SessionStoreContext);

    useEffect(() => {
        // At the point this component is rendered the sessionStore is available
        // enforce by the App component
        if (!sessionStore.authenticated) {
            sessionStore.logout({ preserveLocation: true });
        }
        registerLogoutHandler(() => {
            sessionStore.logout({ preserveLocation: true });
        });

        return () => {
            registerLogoutHandler(null);
        };
    });

    // _app.tsx has kicked off a fetch request to get the user and primary organisation
    // Prevent rendering the children until the user has finished fetching.
    if (!sessionStore.user) {
        // This loading state is consistent with the <App> which always wraps this component
        // so there is no layout shift before the page is first loaded.
        return (
            <AppRoot>
                <AppLoading />
            </AppRoot>
        );
    }

    return (
        <Root>
            {!props.suppressOnboardingController && (
                <SignupOnboardingController />
            )}
            {props.children}
        </Root>
    );
});

const AppRoot = styled.div`
    height: 100%;
`;
