import * as React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

import { grey300, blue400, red400 } from "ui/colors";
import { appFontSize } from "uiKit/theme/fonts";

export class Overlay extends React.Component {
    render() {
        return createPortal(
            <OverlayRoot>{this.props.children}</OverlayRoot>,
            document.getElementById("modalRoot"),
        );
    }
}

const OverlayRoot = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 3;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${appFontSize};
`;
export const ProgressMessage = styled.div`
    width: 50%;
    max-width: 600px;
    display: flex;
    flex-direction: column;

    background: white;
    border: 1px solid ${grey300};
    border-radius: 3px;

    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);

    > p {
        flex: 1 1 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }
`;
export const ErrorMessage = styled(ProgressMessage)`
    color: ${red400};

    > p:not(:first-child) {
        padding-top: 0;
    }
`;
export const ProgressBar = styled.div`
    border-radius: 2px;
    height: 20px;
    background: ${blue400};
    width: ${(props) => Math.min(100, Math.max(1, props.percent || 0))}%;
    transition: width 500ms ease-out;
`;
