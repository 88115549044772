import React from "react";
import { Link } from "ui";
import styled from "styled-components";

const LinkContent = styled.span`
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.2s;
`;
const LinkRoot = styled(Link)`
    &:hover > ${LinkContent} {
        border-bottom-color: inherit;
    }
`;

const ARoot = styled.a`
    &:hover > ${LinkContent} {
        border-bottom-color: inherit;
    }
`;

const RawLink = (props) => (
    <LinkRoot {...props}>
        <LinkContent>{props.children}</LinkContent>
    </LinkRoot>
);

export default RawLink;

export const RawA = (props) => (
    <ARoot {...props}>
        <LinkContent>{props.children}</LinkContent>
    </ARoot>
);
