import React, { useEffect, useLayoutEffect, useRef } from "react";
import useEscapeKeyStack from "ui/_shared/hooks/useEscapeKeyStack";

import { createPortal } from "react-dom";
import styled from "styled-components";
import { appFontSize } from "uiKit/theme/fonts";

interface Props {
    verticalAlign?: "top" | "center";
    suppressOverlay?: boolean;
    closeOnEscape?: boolean;
    closeOnClickOutside?: boolean;
    allowScroll?: Boolean;
    onClose?: () => void;
    children?: React.ReactNode;
}
export default function Modal({
    closeOnEscape = true,
    closeOnClickOutside = false,
    allowScroll = true,
    verticalAlign = "center",
    suppressOverlay = false,
    children,
    onClose,
}: Props) {
    const modalRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (
            modalRef.current &&
            // Let's not stomp on any focus events we have when modals open.
            // This was crushing them if they were set.
            !modalRef.current.contains(document.activeElement)
        ) {
            modalRef.current.focus();
        }
    }, []);

    useLayoutEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "";
        };
    });
    useEscapeKeyStack(function () {
        if (!!closeOnEscape && typeof onClose === "function") {
            onClose();
        }
    });

    function onClick(event) {
        if (
            !!closeOnClickOutside &&
            event.target === wrapperRef.current &&
            typeof onClose === "function"
        ) {
            onClose();
        }
    }

    return createPortal(
        <ModalRootWithOverlay
            tabIndex={-1}
            ref={modalRef}
            suppressOverlay={suppressOverlay}
        >
            <ModalWrapper
                style={{ height: allowScroll ? undefined : "100%" }}
                onClick={onClick}
                ref={wrapperRef}
                verticalAlign={verticalAlign}
                suppressOverlay={suppressOverlay}
            >
                {React.Children.only(children)}
            </ModalWrapper>
        </ModalRootWithOverlay>,
        document.getElementById("modalRoot")!,
    );
}
// Overflow handling of the rest of the app is managed in reset.css
const ModalRootWithOverlay = styled.div<{ suppressOverlay: boolean }>`
    width: 100%;
    height: 100%;
    overflow: auto;
    background: ${(props) =>
        !props.suppressOverlay ? "rgba(0, 0, 0, 0.5)" : "none"};
    position: absolute;
    font-size: ${appFontSize};
`;

const ModalWrapper = styled.div<{
    verticalAlign: "top" | "center";
    suppressOverlay: boolean;
}>`
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: ${(props) =>
        props.verticalAlign === "top" ? "flex-start" : "center"};
    justify-content: center;
    padding: 20px;

    > * {
        box-shadow: ${(props) =>
            !!props.suppressOverlay
                ? "0 0 8px 4px rgba(0, 0, 0, 0.3)"
                : "none"};
    }
`;
