import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

import PrimaryUIButton from "uiKit/button/PrimaryUIButton";

import { WelcomeStepProps } from "../SignupOnboardingController";
import * as api from "data/utils/objectCrud";
import UnexpectedError from "./UnexpectedError";

const COMPANY_SIZE = ["None", "2-10", "11-50", "51-200", "200+"];

export default observer((props: WelcomeStepProps) => {
    const [orgError, setOrgError] = useState<Error | null>(null);
    const [selectedCompanySize, setCompanySize] = useState("");

    useEffect(() => {
        props.editableOrganisation.setAttributes({
            estimatedTeamSize: selectedCompanySize,
        });
    }, [selectedCompanySize, props.editableOrganisation]);

    async function onSubmit(event) {
        event.preventDefault();

        try {
            await api.update(props.editableOrganisation);
            props.onStepComplete();
        } catch (e: any) {
            setOrgError(e[0]);
        }
    }

    return (
        <Root onSubmit={onSubmit}>
            {orgError && <UnexpectedError />}
            <QuestionText>
                How many other team members perform structural calculations?
            </QuestionText>
            <CompanySizeWrapper>
                {COMPANY_SIZE.map((option, idx) => {
                    const checked = selectedCompanySize.includes(option);
                    return (
                        <Radio key={idx}>
                            <input
                                type="radio"
                                name="companySize"
                                value={option}
                                checked={checked}
                                onChange={({ target }) => {
                                    setCompanySize(target.value);
                                }}
                            />
                            {option}
                        </Radio>
                    );
                })}
            </CompanySizeWrapper>
            <SubmitButton
                type="submit"
                disabled={selectedCompanySize.length == 0}
            >
                {props.lastStep ? "Done" : "Next"}
            </SubmitButton>
        </Root>
    );
});

const Root = styled.form`
    max-width: 500px;
    margin: 3em auto 0;
`;

const SubmitButton = styled(PrimaryUIButton)`
    width: 80%;
    margin: 2em auto 0;
`;

const QuestionText = styled.p`
    margin-bottom: 1em;
`;
const CompanySizeWrapper = styled.div`
    margin: 1em 0 2em;
`;
const Radio = styled.label`
    margin: 1em 0;
    display: flex;
    > input {
        appearance: radio;
        margin-right: 1em;
        display: inline-block;
        width: 1.2em;
    }
`;
