import * as React from "react";
import styled from "styled-components";

import { Controls, ErrorButton, ContactLink } from "./ErrorMessage";

type Props = {
    error?: Error;
};

const Title = styled.h2`
    text-align: center;
`;
const Text = styled.p`
    margin: 20px 0;
`;

class GenericError extends React.Component<Props> {
    render() {
        // TODO: Inspect the error and show different messages for timeouts,
        // connectivity issues, etc.
        //
        // PermissionDenied/NotFound are better dealt with in your controller,
        // where you can give a context-specific reason why the user might not
        // have access.
        return (
            <div>
                <Title>Oh no! This isn&apos;t right.</Title>
                <Text>
                    Sorry, something went wrong. Try reloading the page, and if
                    it still doesn&apos;t work, please{" "}
                    <ContactLink>contact support</ContactLink>.
                </Text>

                <Controls>
                    <ErrorButton onClick={() => window.location.reload()}>
                        Reload
                    </ErrorButton>
                </Controls>
            </div>
        );
    }
}
export default GenericError;
