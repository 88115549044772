import React from "react";
import ButtonStyledLink from "./ButtonStyledLink";
import { blue400 } from "ui/colors";

export default function PrimaryButtonStyledLink(props) {
    return (
        <ButtonStyledLink {...props} baseColor={props.baseColor || blue400}>
            <span>{props.children}</span>
        </ButtonStyledLink>
    );
}
