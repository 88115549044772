import React, { createRef } from "react";
import styled, { css } from "styled-components";

import { blue400, grey300, grey400, red400 } from "ui/colors";

let idCounter = 0;

// Using spans here, because div/p tags can't be children of other p tags
// which may happen when we inline the input.
const InputRoot = styled.span`
    display: block;
    width: 100%;
    position: relative;

    margin-top: ${(props) => (props.hasLabel ? "2em" : "0")};
    padding-bottom: ${(props) => (props.hasError ? "20px" : "0")};
`;
const Label = styled.label`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    border: 1px solid transparent;
    border-bottom-width: 3px;

    padding: 0.75em;
    padding-bottom: calc(0.75em - 4px);
    padding-left: ${(props) => (props.icon ? "3.5em" : ".75em")};

    transition-property: transform, font, color, height, padding;
    transition-duration: 0.2s;

    pointer-events: none;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: ${(props) => (props.inputFilledOrFocussed ? "black" : grey400)};

    ${(props) =>
        props.inputFilledOrFocussed &&
        css`
            transform: translateY(-100%);
            font-size: 0.75em;
            height: 2em;
            padding: 0 0.75em;
        `};
`;
const IconWrapper = styled.span`
    position: absolute;
    left: 0;
    top: 0;

    width: 3em;
    height: 100%;
    display: block;
    text-align: center;
    border-radius: 3px 0 0 3px;
    border: 1px solid transparent;
    border-bottom-width: 3px;
    border-right-color: currentColor;
    padding: 10px;

    color: ${grey300};
    > * {
        // We need to make this important because of the specificity of this
        // class and the InlineIcon component that the old icons are wrapped in
        // internally are the same, and sometimes they are loaded in different
        // orders.
        //
        // See https://clearcalcs.slack.com/archives/C022LSGGEFR/p1720413234285669
        // and https://app.clickup.com/t/86cvr0vah
        //
        // TODO: Replace this icon with a new one and use the size prop on it
        // to control size, rather than CSS like this.
        height: 100% !important;
    }
`;
const DisplayLabel = styled.label`
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: white;
    border: 1px solid ${(props) => (props.hasError ? red400 : grey300)};
    border-bottom-width: 3px;
    border-radius: ${(props) => (props.hasError ? "3px 3px 0 0" : "3px")};
    padding: 0.75em;
    padding-bottom: calc(0.75em - 4px);

    padding-left: ${(props) => (props.icon ? "3.5em" : ".75em")};

    transition: border-color 0.3s;
`;
const InputEl = styled.input`
    opacity: 0;
    border: 1px solid ${(props) => (props.hasError ? red400 : grey300)};
    border-bottom-width: 3px;
    border-radius: ${(props) => (props.hasError ? "3px 3px 0 0" : "3px")};
    padding: 0.75em;
    padding-bottom: calc(0.75em - 4px);

    padding-left: ${(props) => (props.icon ? "3.5em" : ".75em")};

    transition: border-color 0.3s;

    &:focus {
        outline: none;
        opacity: 1;

        border-color: ${blue400};
        ~ ${DisplayLabel} {
            display: none;
        }

        ~ ${Label}, ~ ${IconWrapper} {
            color: ${blue400};
        }
    }

    &[disabled] ~ ${DisplayLabel} {
        opacity: 0.3;
    }
`;

// Using spans here, because div/p tags can't be children of other p tags
// which may happen when we inline the input.
const ErrorDisplay = styled.span`
    display: block;
    width: 100%;
    line-height: 1.5;

    background: ${red400};
    color: white;

    padding: 0 0.5em 2px;
    position: absolute;
    z-index: 1;
    width: 100%;
    font-size: 0.75em;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    pointer-events: none;
    opacity: 1;
    transform: translateY(0);

    transition:
        opacity 0.2s,
        transform 0.2s;

    &:empty {
        opacity: 0;
        transform: translateY(-100%);
    }
`;

// type Props = {
//     onValueChange?: (value: string | null) => void,
//     className?: string,
//     name: string,
//     value: ?string, // in "yyyy-mm-dd" format
//     error?: string,

//     disabled: boolean,
//     readOnly: boolean,

//     children?: Children,

//     icon?: React$Element,
//     localeFormat: {
//         year: string,
//         month: string,
//         day: string,
//         timeZone?: string,
//     },
// };
// type State = {
//     id: string,
//     focussed: boolean,
// };
class Input extends React.Component {
    static defaultProps = {
        value: null,
        disabled: false,
        readOnly: false,
        localeFormat: {
            year: "numeric",
            month: "short",
            day: "numeric",
            timeZone: "UTC",
        },
    };

    inputEl = createRef();
    state = {
        id: `inputDate-${idCounter++}`,
        focussed: false,
    };

    constructor(props) {
        super(props);

        this._onInputChange = this._onInputChange.bind(this);
        this._onInputFocus = this._onInputFocus.bind(this);
        this._onInputBlur = this._onInputBlur.bind(this);
    }

    render() {
        let LabelContent;
        if (React.Children.count(this.props.children) > 0) {
            LabelContent = React.Children.only(this.props.children);
        }

        let value = this.props.value || "";

        return (
            <InputRoot
                hasLabel={!!LabelContent}
                className={this.props.className}
                hasError={this.props.error}
            >
                <InputEl
                    id={this.state.id}
                    type="date"
                    value={value}
                    ref={this.inputEl}
                    onChange={this._onInputChange}
                    onFocus={this._onInputFocus}
                    onBlur={this._onInputBlur}
                    hasError={!!this.props.error}
                    disabled={this.props.disabled}
                    readOnly={this.props.readOnly}
                />
                {!!this.props.icon && (
                    <IconWrapper>{this.props.icon}</IconWrapper>
                )}
                <DisplayLabel htmlFor={this.state.id}>
                    {this.props.value
                        ? new Date(this.props.value).toLocaleString(
                              undefined,
                              this.props.localeFormat,
                          )
                        : "\xa0"}
                </DisplayLabel>
                {!!LabelContent && (
                    <Label
                        icon={this.props.icon ? 1 : 0}
                        inputFilledOrFocussed={
                            this.props.value || this.state.focussed ? 1 : 0
                        }
                        htmlFor={this.state.id}
                    >
                        {LabelContent}
                    </Label>
                )}
                <ErrorDisplay>{this.props.error}</ErrorDisplay>
            </InputRoot>
        );
    }

    _onInputChange({ target }) {
        if (this.props.onValueChange) {
            this.props.onValueChange(target.value !== "" ? target.value : null);
        }
    }
    _onInputFocus() {
        this.setState({
            focussed: true,
        });
    }
    _onInputBlur() {
        this.setState({
            focussed: false,
        });
    }

    focus() {
        if (this.inputEl.current) {
            this.inputEl.current.focus();
        }
    }
}
export default Input;
export { InputEl };
