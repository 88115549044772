import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

import Input from "uiKit/input/Input";
import FormattableDateInput from "uiKit/input/FormattableDate";
import PrimaryUIButton from "uiKit/button/PrimaryUIButton";

import { WelcomeStepProps } from "../SignupOnboardingController";
import * as api from "data/utils/objectCrud";

import { blue400, grey400, blue300, grey200 } from "ui/colors";
import UnexpectedError from "./UnexpectedError";
import { ValidationError } from "errors";

const OCCUPATION_OPTIONS = [
    {
        imgSrc: require("onboarding/assets/student.svg").default,
        label: "Student",
    },
    {
        imgSrc: require("onboarding/assets/teacher.svg").default,
        label: "Teacher",
    },
    {
        imgSrc: require("onboarding/assets/researcher.svg").default,
        label: "Researcher",
    },
];

export default observer((props: WelcomeStepProps) => {
    const [userError, setUserError] = useState<Error | null>(null);
    const [orgError, setOrgError] = useState<Error | null>(null);

    function updateOccupation(ev) {
        props.editableUser.setAttributes({
            occupation: ev.target.value,
        });
    }

    function updateOrganisationAttribute(name) {
        return (newValue) => {
            props.editableOrganisation.setAttributes({ [name]: newValue });
        };
    }

    async function onSubmit(event) {
        event.preventDefault();
        let errors = false;

        try {
            await api.update(props.editableUser);
        } catch (e: any) {
            setUserError(e);
        }

        try {
            await api.update(props.editableOrganisation);
        } catch (e: any) {
            setOrgError(e[0]);
            errors = true;
        }

        if (errors) return;

        props.onStepComplete();
    }

    const unexpectedError =
        (userError && !(userError instanceof ValidationError)) ||
        (orgError && !(orgError instanceof ValidationError));

    return (
        <Root onSubmit={onSubmit}>
            {unexpectedError && <UnexpectedError />}
            <OptionsContainer>
                {OCCUPATION_OPTIONS.map((option, idx) => (
                    <OptionWrapper key={idx}>
                        <input
                            type="radio"
                            id={`occupation-${idx}`}
                            name="occupation"
                            value={option.label}
                            onChange={updateOccupation}
                            checked={
                                props.editableUser.attributes.occupation ===
                                option.label
                            }
                        />
                        <Option htmlFor={`occupation-${idx}`}>
                            <img src={option.imgSrc} alt="" />
                            <p>{option.label}</p>
                        </Option>
                    </OptionWrapper>
                ))}
            </OptionsContainer>
            {props.editableUser.attributes.occupation === "Student" && (
                <>
                    <Input
                        value={
                            props.editableOrganisation.attributes
                                .academicCourseName
                        }
                        onValueChange={updateOrganisationAttribute(
                            "academicCourseName",
                        )}
                    >
                        <span>What course are you studying?</span>
                    </Input>

                    <FormattableDateInput
                        value={
                            props.editableOrganisation.attributes
                                .academicCourseEnd
                        }
                        onValueChange={updateOrganisationAttribute(
                            "academicCourseEnd",
                        )}
                    >
                        <span>When are you graduating?</span>
                    </FormattableDateInput>
                </>
            )}
            {props.editableUser.attributes.occupation === "Teacher" && (
                <>
                    <Input
                        value={
                            props.editableOrganisation.attributes
                                .academicCourseName
                        }
                        onValueChange={updateOrganisationAttribute(
                            "academicCourseName",
                        )}
                    >
                        <span>What course are you teaching?</span>
                    </Input>
                </>
            )}
            {props.editableUser.attributes.occupation === "Researcher" && (
                <>
                    <Input
                        value={
                            props.editableOrganisation.attributes
                                .academicCourseName
                        }
                        onValueChange={updateOrganisationAttribute(
                            "academicCourseName",
                        )}
                    >
                        <span>What is the title of your research?</span>
                    </Input>

                    <FormattableDateInput
                        value={
                            props.editableOrganisation.attributes
                                .academicCourseEnd
                        }
                        onValueChange={updateOrganisationAttribute(
                            "academicCourseEnd",
                        )}
                    >
                        <span>When are you expected to complete?</span>
                    </FormattableDateInput>
                </>
            )}

            <SubmitButton type="submit">
                {props.lastStep ? "Done" : "Next"}
            </SubmitButton>
        </Root>
    );
});

const Root = styled.form`
    margin-top: 3em;
`;
const OptionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const Option = styled.label`
    display: block;
    background: white;
    margin-bottom: 1em;
    padding: 2em 1em;

    border: 1px solid ${grey400};
    border-bottom-width: 3px;
    border-radius: 10px;

    text-align: center;

    transition: background 0.5s;

    &:hover {
        background: ${grey200};
    }

    > img {
        width: 7em;
        height: 7em;
        margin-bottom: 2em;
        border-radius: 100%;
    }
`;
const OptionWrapper = styled.div`
    display: relative;
    width: 25%;
    margin: 0em 3% 1em;

    > input {
        position: absolute;
        opacity: 0;

        &:checked + ${Option} {
            border-color: ${blue400};
            background: ${blue300};
        }
    }
`;
const SubmitButton = styled(PrimaryUIButton)`
    width: 80%;
    margin: 2em auto 0;
`;
