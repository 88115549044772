import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { ProgressBar, ProgressMessage } from "uiKit/overlay";
import { RawA } from "uiKit/link/RawLink";
import { blue400 } from "ui/colors";
import isMafiSubdomain from "embeddedCalcs/utils/isMafiSubdomain";
import isItiSubdomain from "embeddedCalcs/utils/isItiSubdomain";

const BUILDING_STANDARD_TO_PROJECT_DEFAULTS_LINK = {
    AU: "https://clearcalcs.com/support/manage-projects/project-defaults-au-nz",
    NZ: "https://clearcalcs.com/support/manage-projects/project-defaults-au-nz",
    CA: "https://clearcalcs.com/support/manage-projects/project-defaults-us",
    US: "https://clearcalcs.com/support/manage-projects/project-defaults-us",
    EU: "https://clearcalcs.com/support/manage-projects/project-defaults-eu",
};

export default observer((props) => {
    return (
        <Root>
            <h3>We&apos;re preparing your export.</h3>
            {!(isMafiSubdomain() || isItiSubdomain()) && (
                <div>
                    <p>
                        Here&apos;s some ideas to make your next project even
                        faster!
                    </p>
                    <HelpLinks>
                        <RawA
                            href="https://clearcalcs.com/support/advanced-tips-and-tricks/linking-reactions-between-beams-and-columns-load-path-tracking"
                            target="_blank"
                        >
                            <img
                                src={
                                    require("newProject/images/exportingLoadLink.svg")
                                        .default
                                }
                                alt=""
                            />
                            <LinkText>
                                Eliminate copy/paste with load linking
                            </LinkText>
                        </RawA>
                        <RawA
                            href="https://clearcalcs.com/support/get-started-with-clearcalcs/how-to-use-preferred-sections-and-autosize"
                            target="_blank"
                        >
                            <img
                                src={
                                    require("newProject/images/exportingAutoSize.svg")
                                        .default
                                }
                                alt=""
                            />
                            <LinkText>Design faster with AutoSize</LinkText>
                        </RawA>
                        <RawA
                            href={
                                BUILDING_STANDARD_TO_PROJECT_DEFAULTS_LINK[
                                    props.buildingStandard
                                        ? props.buildingStandard.toUpperCase()
                                        : "US"
                                ]
                            }
                            target="_blank"
                        >
                            <img
                                src={
                                    require("newProject/images/exportingDefaults.svg")
                                        .default
                                }
                                alt=""
                            />
                            <LinkText>Save time with project defaults</LinkText>
                        </RawA>
                    </HelpLinks>
                </div>
            )}
            <p>This could take up to a minute or two for large projects.</p>
            {props.exportModel && (
                <ProgressBar
                    percent={props.exportModel.attributes.progressPercent}
                />
            )}
        </Root>
    );
});

const Root = styled(ProgressMessage)`
    > h3 {
        margin-top: 1em;
    }
    text-align: center;
`;

const HelpLinks = styled.div`
    display: flex;
    justify-content: space-evenly;

    > * {
        flex: 1 1 30%;
        color: ${blue400};
    }

    img {
        display: block;
        margin: 0 auto;
        max-height: 120px;
    }
`;

const LinkText = styled.span`
    line-height: 1.5;
`;
