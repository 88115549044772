import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

import { FormSelect } from "uiKit/select";
import PrimaryUIButton from "uiKit/button/PrimaryUIButton";

import { ValidationError } from "errors";

import { WelcomeStepProps } from "../SignupOnboardingController";
import * as api from "data/utils/objectCrud";
import getUnitSystemOptions from "data/utils/getUnitSystemOptions";
import UnexpectedError from "./UnexpectedError";

export default observer((props: WelcomeStepProps) => {
    const [orgError, setOrgError] = useState<Error | null>(null);

    async function onSubmit(event) {
        event.preventDefault();

        let errors = false;

        try {
            await api.update(props.editableOrganisation);
        } catch (e: any) {
            setOrgError(e[0]);
            errors = true;
        }

        if (errors) return;

        props.onStepComplete();
    }
    const unexpectedError = orgError && !(orgError instanceof ValidationError);

    // Pre-set building standard select from user's origin country isoCode
    if (
        !props.editableOrganisation.attributes.preferredBuildingStandard &&
        props.projectDefaultsSheetTemplates
    ) {
        props.editableOrganisation.setAttributes({
            preferredBuildingStandard:
                props.projectDefaultsSheetTemplates.models.find(
                    (st) =>
                        st.attributes.buildingStandards![0] ===
                        props.editableOrganisation.attributes.country,
                )?.attributes.buildingStandards![0] || "US",
        });
    }

    return (
        <Root onSubmit={onSubmit}>
            {unexpectedError && <UnexpectedError />}
            <FormSelect
                options={
                    props.projectDefaultsSheetTemplates &&
                    props.projectDefaultsSheetTemplates.models.map((st) => ({
                        value: st.attributes.buildingStandards![0],
                        label: st.attributes.name,
                    }))
                }
                value={
                    props.editableOrganisation.attributes
                        .preferredBuildingStandard
                }
                onChange={(item) => {
                    props.editableOrganisation.setAttributes({
                        preferredBuildingStandard: item ? item.value : null,
                    });
                }}
                required
            >
                Default building standard for new projects
            </FormSelect>
            <FormSelect
                options={getUnitSystemOptions(
                    props.editableOrganisation.attributes
                        .preferredBuildingStandard,
                )}
                value={
                    props.editableOrganisation.attributes.preferredUnitSystem
                }
                onChange={(item) => {
                    const preferredUnitSystem = item ? item.value : null;
                    props.editableOrganisation.setAttributes({
                        preferredUnitSystem,
                    });
                }}
                required
            >
                Default unit system for new projects
            </FormSelect>
            <SubmitButton type="submit">
                {props.lastStep ? "Done" : "Next"}
            </SubmitButton>
        </Root>
    );
});

const Root = styled.form`
    max-width: 500px;
`;
const SubmitButton = styled(PrimaryUIButton)`
    width: 80%;
    margin: 2em auto 0;
`;
