import * as React from "react";
import styled from "styled-components";

import { IconCross } from "ui/icons";
import { grey100, blue400 } from "ui/colors";

import UIButton from "uiKit/button/UIButton";

export const Dialog = styled.div`
    background: white;
    border-radius: 5px;
    /* Don't make this overflow: hidden - you'll cause components (eg. dropdowns) to be clipped */
`;

const TitleRoot = styled.header`
    width: 100%;

    background: white;
    color: ${blue400};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 2px;
    flex-basis: content;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    > p {
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;
const CloseButton = styled(UIButton)`
    width: 45px;
`;

export function DialogTitle({ onClose, children, className }) {
    return (
        <TitleRoot className={className}>
            <p>{children}</p>
            {onClose && (
                <CloseButton onClick={onClose}>
                    <IconCross size={16} />
                </CloseButton>
            )}
        </TitleRoot>
    );
}

export const DialogContent = styled.div`
    margin: 10px;

    p {
        margin: 1em 0;
    }

    a:link {
        text-decoration: underline;
    }
`;

export const Controls = styled.div`
    background: ${grey100};
    padding: 10px;
    display: flex;
    justify-content: flex-end;

    > * {
        min-width: 150px;
        width: auto;
        margin-right: 1ch;

        &:last-child {
            margin-right: 0;
        }
    }
`;
