import React from "react";
import UIButton from "./UIButton";
import { blue400 } from "ui/colors";

const PrimaryUIButton = (props) => (
    <UIButton {...props} baseColor={blue400}>
        {props.children}
    </UIButton>
);

export default PrimaryUIButton;
