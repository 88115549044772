import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

import PrimaryUIButton from "uiKit/button/PrimaryUIButton";

import { WelcomeStepProps } from "../SignupOnboardingController";
import * as api from "data/utils/objectCrud";
import UnexpectedError from "./UnexpectedError";

export const CALCULATION_FREQUENCY = [
    "One off",
    "Daily",
    "Monthly",
    "Work fluctuates throughout the year",
];

export default observer((props: WelcomeStepProps) => {
    const [userProfileError, setUserProfileError] = useState<Error | null>(
        null,
    );
    const [selectedCalculationFrequency, setCalculationFrequency] =
        useState("");

    useEffect(() => {
        props.editableOrganisation.setAttributes({
            calculationFrequency: selectedCalculationFrequency,
        });
    }, [selectedCalculationFrequency, props.editableOrganisation]);

    async function onSubmit(event) {
        event.preventDefault();

        try {
            await api.update(props.editableOrganisation);
            props.onStepComplete();
        } catch (e: any) {
            setUserProfileError(e[0]);
        }
    }

    return (
        <Root onSubmit={onSubmit}>
            {userProfileError && <UnexpectedError />}
            <QuestionText>
                How often do you perform structural calculations?
            </QuestionText>
            <CalculationFrequencyWrapper>
                {CALCULATION_FREQUENCY.map((option, idx) => {
                    const checked =
                        selectedCalculationFrequency.includes(option);
                    return (
                        <Radio key={idx}>
                            <input
                                type="radio"
                                name="calculationFrequency"
                                value={option}
                                checked={checked}
                                onChange={({ target }) => {
                                    setCalculationFrequency(target.value);
                                }}
                            />
                            {option}
                        </Radio>
                    );
                })}
            </CalculationFrequencyWrapper>
            <SubmitButton
                type="submit"
                disabled={selectedCalculationFrequency.length == 0}
            >
                {props.lastStep ? "Done" : "Next"}
            </SubmitButton>
        </Root>
    );
});

const Root = styled.form`
    max-width: 500px;
    margin: 3em auto 0;
`;

const SubmitButton = styled(PrimaryUIButton)`
    width: 80%;
    margin: 2em auto 0;
`;

const QuestionText = styled.p`
    margin-bottom: 1em;
`;
const CalculationFrequencyWrapper = styled.div`
    margin: 1em 0 2em;
`;
const Radio = styled.label`
    margin: 1em 0;
    display: flex;
    > input {
        appearance: radio;
        margin-right: 1em;
        display: inline-block;
        width: 1.2em;
    }
`;
