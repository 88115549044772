import React, { createRef } from "react";
import styled, { css } from "styled-components";

import { red400, blue400, grey300, grey400 } from "ui/colors";

let idCounter = 0;
export type Props = React.ComponentPropsWithRef<any> & {
    name?: string;
    autoComplete?: string;
    autoFocus?: boolean;
    className?: string;
    error?: string;

    readOnly?: boolean;
    disabled?: boolean;
    required?: boolean;
    maxLength?: number;
    min?: string | number;

    value: string | number | null;
    onValueChange: (value: string | null) => void;
    editingMode?: boolean;

    onBlur?: (ev: FocusEvent) => void;
    onFocus?: (ev: FocusEvent) => void;

    icon?: React.ReactNode;
    dataCy?: string;
};

type TypeProps = Props & {
    type: "text" | "email" | "password" | "number";
};
type State = {
    id: string;
    focussed: boolean;
};
class BuilderInput extends React.Component<TypeProps, State> {
    static defaultProps = {
        type: "text",
    };
    state: State = {
        id: `input-${idCounter++}`,
        focussed: false,
    };
    inputEl = createRef<HTMLInputElement>();

    render() {
        const filled = !!this.props.value;
        const focussed = this.state.focussed;
        const hideBordersAndLabel = !focussed && !!this.props.editingMode;

        return (
            <Root
                className={this.props.className}
                hasError={!!this.props.error}
            >
                <Input
                    name={this.props.name}
                    autoComplete={this.props.autoComplete}
                    autoFocus={this.props.autoFocus}
                    ref={this.inputEl}
                    type={this.props.type}
                    value={!this.props.value ? "" : this.props.value.toString()}
                    onChange={this._onChange}
                    onFocus={this._onInputFocus}
                    onBlur={this._onInputBlur}
                    hasError={!!this.props.error}
                    maxLength={this.props.maxLength}
                    hasVisibleLabel={
                        !!this.props.children && !hideBordersAndLabel
                    }
                    hideBorders={hideBordersAndLabel}
                    min={this.props.min}
                    icon={!!this.props.icon}
                    required={!!this.props.required}
                    data-cy={this.props.dataCy}
                />
                {!!this.props.icon && (
                    <IconWrapper>{this.props.icon}</IconWrapper>
                )}
                <Label
                    inputFilledOrFocussed={!!(filled || focussed)}
                    hideLabel={!!this.props.value && hideBordersAndLabel}
                    htmlFor={this.state.id}
                    icon={!!this.props.icon}
                >
                    <LabelContent>{this.props.children}</LabelContent>
                </Label>
                <ErrorDisplay>{this.props.error}</ErrorDisplay>
            </Root>
        );
    }

    private _onChange = (ev) => {
        if (this.props.onValueChange) {
            this.props.onValueChange(
                ev.target.value !== "" ? ev.target.value : null,
            );
        }
    };
    private _onInputFocus = (ev) => {
        this.setState({
            focussed: true,
        });
        if (this.props.readOnly) {
            ev.target.select();
        }
        if (this.props.onFocus) {
            this.props.onFocus(ev);
        }
    };
    private _onInputBlur = (ev) => {
        this.setState({
            focussed: false,
        });
        if (this.props.onBlur) {
            this.props.onBlur(ev);
        }
    };

    focus() {
        if (this.inputEl.current) {
            this.inputEl.current.focus();
        }
    }
}
export default BuilderInput;

const Root = styled.span<{ hasError: boolean }>`
    display: block;
    width: 100%;
    position: relative;
    text-align: left;
`;

const LabelContent = styled.span`
    transition-property: font;
    transition-duration: 0.2s;
`;

const Label = styled.label<{
    inputFilledOrFocussed: boolean;
    hideLabel: boolean;
    icon: boolean;
}>`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    padding: 0.75em;
    padding-left: ${(props) => (props.icon ? "3.5em" : ".75em")};
    border: 1px solid transparent;
    color: ${grey400};

    pointer-events: none;

    transition-property: font, color, height, padding, opacity;
    transition-duration: 0.2s;
    ${(props) =>
        props.inputFilledOrFocussed &&
        css`
            color: black;
            padding: 0 0.75em;
            padding-left: ${() => (props.icon ? "3.5em" : ".75em")};

            line-height: 1;
            > * {
                font-size: 0.75em;
            }
        `};

    opacity: ${(props) => (props.hideLabel ? 0 : 1)};
`;

const Input = styled.input<{
    hasError: boolean;
    hasVisibleLabel: boolean;
    hideBorders: boolean;
    icon: boolean;
}>`
    border: 1px solid;
    border-bottom-width: 3px;
    border-radius: ${(props) => (props.hasError ? "3px 3px 0 0" : "3px")};

    border-color: ${(props) =>
        props.hasError ? red400 : props.hideBorders ? "transparent" : grey300};
    padding: 0.75em;
    padding-bottom: calc(0.75em - 4px);
    padding-left: ${(props) => (props.icon ? "3.5em" : ".75em")};
    background: ${(props) => (props.hideBorders ? "transparent" : "white")};

    ${(props) =>
        props.hasVisibleLabel
            ? css`
                  padding-top: 1em;
                  padding-bottom: calc(0.5em - 4px);
              `
            : ""}

    &:hover {
        border-color: ${(props) => (props.hasError ? red400 : grey300)};
    }

    transition:
        border-color 0.3s,
        padding 0.3s,
        color 0.3s;

    &:focus {
        color: black;
        border-color: ${blue400};
        outline: none;

        ~ ${Label} {
            color: ${blue400};
        }
    }

    &[disabled] {
        opacity: 0.3;
    }
`;

// Using spans here, because div/p tags can't be children of other p tags
// which may happen when we inline the input.
const ErrorDisplay = styled.span`
    display: block;
    width: 100%;
    line-height: 1.5;

    background: ${red400};
    color: white;

    padding: 0 0.5em 2px;
    position: absolute;
    z-index: 1;
    width: 100%;
    font-size: 0.75em;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    pointer-events: none;
    opacity: 1;
    transform: translateY(0);

    transition:
        opacity 0.2s,
        transform 0.2s;

    &:empty {
        opacity: 0;
        transform: translateY(-100%);
    }
`;

const IconWrapper = styled.span`
    position: absolute;
    left: 0;
    top: 0;

    width: 3em;
    height: 100%;
    display: block;
    text-align: center;
    border-radius: 3px 0 0 3px;
    border: 1px solid transparent;
    border-bottom-width: 3px;
    border-right-color: currentColor;
    padding: 10px;

    color: ${grey300};
    > * {
        // We need to make this important because of the specificity of this
        // class and the InlineIcon component that the old icons are wrapped in
        // internally are the same, and sometimes they are loaded in different
        // orders.
        //
        // See https://clearcalcs.slack.com/archives/C022LSGGEFR/p1720413234285669
        // and https://app.clickup.com/t/86cvr0vah
        //
        // TODO: Replace this icon with a new one and use the size prop on it
        // to control size, rather than CSS like this.
        height: 100% !important;
    }
`;
