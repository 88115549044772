export type UnitSystemOptions = {
    value: string;
    label: string;
}[];

export const UNIT_SYSTEM_OPTIONS: UnitSystemOptions = [
    { value: "MKS", label: "Metric (MKS)" },
    { value: "FPS", label: "Imperial (Pounds)" },
];

export default function getUnitSystemOptions(
    buildingStandard: string | null,
): UnitSystemOptions {
    return buildingStandard === "US" || buildingStandard === "CA"
        ? UNIT_SYSTEM_OPTIONS
        : UNIT_SYSTEM_OPTIONS.filter(({ value }) => value === "MKS");
}
